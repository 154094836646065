import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Typography, Autocomplete, TextField, Button, Modal, Checkbox, IconButton } from '@mui/material'
import { CheckBoxOutlineBlank, CheckBox as CheckBoxIcon, AddCircle } from '@mui/icons-material'
import { GetPropertyContacts } from '@api'
import { useSelector } from 'react-redux'
import { isEmail } from '@utils/helpers'
import Images from '@/assets/images'

const icon = <CheckBoxOutlineBlank fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
const FREQUENCY = [
  { id: 'Daily', name: 'Daily' },
  { id: 'Weekly', name: 'Weekly' },
  { id: 'Monthly', name: 'Monthly' },
]

interface Props {
  onClose: () => void
  onSend: (ContactEmails: string[], Emails: string[], Cadence: string[]) => void
  onlyContact?: boolean
  gId?: string
  bId?: string
  showCadence?: boolean
  title?: string
  emails?: string[]
  okText?: string
  cadenceMultiple?: boolean
  cadenceValue?: string[]
}

const SendEmail: React.FC<Props> = React.memo(
  ({ onClose, onSend, onlyContact, bId: id, showCadence, title, emails, ...props }) => {
    const { okText = 'Send', cadenceMultiple = true, cadenceValue = [], gId = '' } = props
    const classes = useStyles()
    const autocompleteRef = useRef<HTMLInputElement | null>(null)
    const { selectedPropertyId } = useSelector((state) => state.profile)
    const bId = id || selectedPropertyId
    const [contacts, setContacts] = useState<Record<string, any>[]>([])
    const [selectedContacts, setSelectedContacts] = useState<Record<string, any>[]>([])
    const [emailList, setEmailList] = useState<string[]>(emails ? emails : [''])
    const [errorList, setErrorList] = useState<boolean[]>([])
    const [cadence, setCadence] = useState<Record<string, any>[]>(FREQUENCY.filter((v) => cadenceValue.includes(v.id)))
    let sendDisabled = !selectedContacts.length && !emailList.filter((v) => v).length
    if (showCadence) {
      sendDisabled = sendDisabled || !cadence.length
    }

    const addMore = () => {
      setEmailList((pre) => [...pre, ''])
    }
    const onDelete = (index: number) => {
      setEmailList((pre) => {
        pre.splice(index, 1)
        return [...pre]
      })
      setErrorList((pre) => {
        pre.splice(index, 1)
        return [...pre]
      })
    }
    const onSendClick = () => {
      const errorList: boolean[] = []
      if (emailList.filter((v) => v).length) {
        emailList.forEach((email) => {
          if (!isEmail(email)) {
            errorList.push(true)
          } else {
            errorList.push(false)
          }
        })
        setErrorList(errorList)
      }
      if (errorList.filter((v) => v).length) {
        return
      }
      onSend(
        selectedContacts.map((v) => v.Email),
        emailList.filter((v) => v),
        cadence.map((v) => v.id)
      )
    }

    useEffect(() => {
      GetPropertyContacts({
        filter: JSON.stringify({ gId, bId }),
        pagination: JSON.stringify({ page: 0, perPage: 10000 }),
      }).then((res: any) => {
        setContacts(res.Contacts)
      })
    }, [bId, gId])

    return (
      <Modal open={true} disableEscapeKeyDown>
        <Box className={classes.modal}>
          <Typography variant="h5" sx={{ fontSize: '16px', marginBottom: '24px' }}>
            {title || 'Kindly choose from the available contacts or input a new email address for sending.'}
          </Typography>

          <Autocomplete
            disableClearable
            onChange={(_, newValue) => setSelectedContacts(newValue)}
            options={contacts}
            multiple
            disableCloseOnSelect
            sx={{ marginBottom: '24px' }}
            value={selectedContacts}
            getOptionLabel={(option: any) => option?.FullName?.en}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Contacts" />}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option?.FullName?.en}
                </li>
              )
            }}
            renderTags={(value) => {
              if (value?.length > 0)
                return <div className={classes.tag}>{value.map((v) => v?.FullName?.en)?.join(',')}</div>
              return null
            }}
          />

          {!onlyContact &&
            emailList.map((email, i) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }} key={i}>
                  <TextField
                    label={'Email'}
                    value={email}
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      setEmailList((pre) => {
                        pre[i] = e.target.value
                        return [...pre]
                      })
                    }
                    error={errorList[i]}
                    helperText={errorList[i] && 'Invalid Email'}
                  />
                  <IconButton sx={{ marginLeft: '6px' }} onClick={() => onDelete(i)}>
                    <img src={Images.iconDelete} alt="logo" />
                  </IconButton>
                </Box>
              )
            })}

          {showCadence && (
            <Autocomplete
              disableClearable
              onChange={(_, newValue) => {
                if (cadenceMultiple) {
                  return setCadence(newValue)
                }
                setCadence((pre) => {
                  const value = newValue[newValue.length - 1] || pre[0]
                  return [value]
                })
                if (autocompleteRef.current) {
                  autocompleteRef.current?.blur()
                }
              }}
              options={FREQUENCY}
              multiple
              disableCloseOnSelect
              sx={{ marginBottom: '24px' }}
              value={cadence}
              getOptionLabel={(option: any) => option?.name}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Cadence" inputRef={autocompleteRef} />
              )}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    {cadenceMultiple && (
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    )}
                    {option?.name}
                  </li>
                )
              }}
              renderTags={(value) => {
                if (value?.length > 0) return <div className={classes.tag}>{value.map((v) => v?.name)?.join(', ')}</div>
                return null
              }}
            />
          )}
          {!onlyContact && (
            <Button variant="text" startIcon={<AddCircle />} onClick={addMore}>
              Add More
            </Button>
          )}

          <Box sx={{ textAlign: 'right', marginTop: '16px' }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onSendClick} variant="contained" color="primary" disabled={sendDisabled}>
              {okText}
            </Button>
          </Box>
        </Box>
      </Modal>
    )
  }
)

const useStyles = makeStyles(() => ({
  modal: {
    width: 500,
    padding: 24,
    maxHeight: '80%',
    backgroundColor: '#fff',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    borderRadius: 6,
  },
  tag: {
    position: 'absolute',
    overflow: 'hidden',
    left: 16,
    right: 40,
    color: '#4D4D4D',
    zIndex: -1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

export default SendEmail
