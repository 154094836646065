import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import CustomTextField from '@component/Form/CustomTextField'
import { makeStyles } from '@mui/styles'
import useSetState from '@/hooks/useSetState'
import { isCanSavaQr } from '@/utils'
import FieldType from '@/utils/FieldType'
import { QRCodeFunctionType } from '@/types'
import WithDefaultData from '@/hoc/WithDefaultData'
import DragFormPanel from '@component/Form/Form/DragFormPanel'
import UploadFile from '@component/Form/UploadFile'
import RichTextEdit from '@component/Form/richText'

const language = 'en'

type FieldItem = typeof FieldType.DropDownSelection

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 16px 16px',
    },
    date: {
      margin: '20px 0 0',
    },
    upload: {
      marginTop: 20,
    },
  }
})

interface Props {
  onChange?: (staticForm: any) => void
  value: any
  groupId: string
  buildingId: string
  functionType: QRCodeFunctionType
  disabledEdit?: boolean
}

const LeadCapture: React.FC<Props> = ({ onChange, value, functionType, disabledEdit = false, groupId, buildingId }) => {
  const classes = useStyles()
  const refValue = useRef(value)
  const isEv = useMemo(() => functionType === QRCodeFunctionType.EVRequest, [functionType])
  const isLeadCapture = useMemo(() => functionType === QRCodeFunctionType.LeadCapture, [functionType])
  const onlyMoveArr = useMemo(() => {
    const arr = ['Q001', 'Q002', 'Q003']
    if (isEv) {
      arr.push('Q004')
      return arr
    }
    return arr
  }, [isEv])
  const [state, setState] = useSetState({
    Details: value?.Details || '',
    EventName: value?.EventName || '',
    EventLocation: value?.EventLocation || '',
    TermConditionUrl: value?.TermConditionUrl || '',
    Fields: (value?.Fields || []) as FieldItem[],
  })
  const { Fields, Details, EventName, EventLocation, TermConditionUrl } = state
  const onChangeFile = useCallback(
    (file: string) => {
      setState({ TermConditionUrl: file })
    },
    [onChange, value]
  )
  const onInput = (value: string, name: string) => {
    // @ts-ignore
    setState({ [name]: value })
  }
  const onChangeField = useCallback((fields: FieldItem[]) => {
    setState({ Fields: fields || [] })
  }, [])

  useEffect(() => {
    if (onChange) {
      const data = {}
      const newData = {
        Details,
        TermConditionUrl,
        Fields: Fields || [],
      }
      if (isEv) {
        Object.assign(newData, { EventLocation })
      } else {
        Object.assign(newData, { EventName })
      }
      Object.assign(data, refValue.current, newData, { CanSave: isCanSavaQr(functionType, newData) })
      onChange(data)
    }
  }, [onChange, Fields, Details, EventName, EventLocation, TermConditionUrl])

  useEffect(() => {
    refValue.current = value
  }, [value])
  const name = isEv ? 'EventLocation' : 'EventName'
  const label = isEv ? 'EV Spot  Location' : 'Lead Capture Event Name'
  const fieldValue = isEv ? EventLocation : EventName
  return (
    <div className={classes.container}>
      <CustomTextField
        required
        label={label}
        disabled={disabledEdit}
        value={fieldValue}
        name={name}
        onInputChange={onInput}
        variant="outlined"
      />
      {isEv && (
        <CustomTextField
          label="Details"
          value={Details}
          disabled={disabledEdit}
          placeholder="type here..."
          name="Details"
          onInputChange={onInput}
          variant="outlined"
          multiline
        />
      )}
      {isLeadCapture && (
        <RichTextEdit
          htmlContent={Details}
          onSubmit={(value) => setState({ Details: value })}
          disabledEdit={disabledEdit}
        />
      )}

      <DragFormPanel
        disabled={disabledEdit}
        language={language}
        data={Fields}
        onChangeField={onChangeField}
        onlyMoveArr={onlyMoveArr}
      />
      <UploadFile
        className={classes.upload}
        value={TermConditionUrl}
        filePath={`Teams/${groupId}/${buildingId}`}
        onChange={onChangeFile}
        disabledInput
        label="Terms & Conditions (optional)"
      />
    </div>
  )
}

export default WithDefaultData(LeadCapture)
