import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, FormControlLabel, IconButton, MenuItem, Switch, TextField, Typography } from '@mui/material'
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox } from '@mui/material'
import { Close, Telegram, CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material'
import MobilePhone from './MobilePhone'
import {
  addPropertyContact,
  editPropertyContact,
  getPropertyVendors,
  GetRoles,
  sendPortalAccess,
  getPropertyList,
  addPortfolioContact,
  editPortfolioContact,
} from '@api'
import SnackBar from '@component/SnackBar'
import { isEmail } from '@utils/helpers'
import { makeStyles } from '@mui/styles'
import { ContactsITF } from '@/types/group'
import { DEFAULT_PERMISSIONS } from '@constant/contacts'
import useSetState from '@hooks/useSetState'

const language = 'en'
const ThirdParty = '3rd party vendor'
const icon = <CheckBoxOutlineBlank fontSize="small" />
const checkedIcon = <CheckBox fontSize="small" />

const useStyles = makeStyles(() => {
  return {
    subTitle: {
      display: 'flex',
      fontSize: 20,
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
    },
    title: {
      fontSize: 24,
      fontWeight: 600,
    },
    box: {
      backgroundColor: '#fff',
      padding: '24px 16px 16px !important',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      minHeight: '400px',
      flexDirection: 'row',
      alignContent: 'flex-start',
    },
    textField: {
      flex: '45% 1 0',
      margin: '0px 12px 24px',
    },
    select: {
      '&:focus': {
        backgroundColor: '#fff',
      },
    },
    formSwitch: {
      display: 'flex',
      flex: 1,
      borderRadius: 6,
      height: 56,
      margin: 0,
      border: '1px solid #BCBCBC',
    },
    formLabel: {
      flex: 1,
      paddingLeft: 16,
    },
    smallTitle: {
      marginBottom: 16,
      fontSize: 20,
      fontWeight: 500,
    },
    access: {
      color: '#466CB5',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      textDecorationLine: 'underline',
      cursor: 'pointer',
      flex: 1,
    },
    tag: {
      position: 'absolute',
      overflow: 'hidden',
      left: 16,
      right: 40,
      color: '#4D4D4D',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      zIndex: 1,
    },
  }
})

interface Props {
  onClose: (data?: ContactsITF) => void
  item: Record<string, any> | null
  disabledUpdate?: boolean
  survey?: boolean
  gId: string
  bId: string
  portfolioTeam?: boolean
}

const OWNERSHIP = [
  { label: 'In House', value: 'InHouse' },
  { label: 'OutSourced', value: 'OutSourced' },
]
interface PermissionItemProps {}
// const PermissionItem: React.FC<PermissionItemProps> = ()=>{}
const ContactCreate: React.FC<Props> = ({ onClose, item: state, disabledUpdate, gId, bId, survey, portfolioTeam }) => {
  const classes = useStyles()
  const ContactMemberId = state?.MemberId || ''
  const ContactUserId = state?.UserId || ''
  const [option, setOption] = useState<any[]>([])
  const [permissionSet, setPermissionSet] = useState<Record<string, boolean>>()
  const [showPermission, setshowPermission] = useState(false)
  const [vendorOption, setVendorOption] = useState<Record<string, any>[]>([])
  const [validate, setValidate] = useState(false)
  const [{ propertyList }, setState] = useSetState({ propertyList: [] as Record<string, any>[] })
  const [data, setData] = useState([
    { name: 'Name', label: 'Contact Name', value: '', required: true },
    { name: 'Role', label: 'User Role', value: '', required: true },
    { name: 'Phone', label: 'Mobile No.', value: '', required: true },
    { name: 'Email', label: 'Email Address', value: '', required: true },
    { name: 'Title', label: 'Job Title', value: '', required: false },
    { name: 'VendorId', label: 'Vendor Name', value: '', required: true, VendorName: '' },
    { name: 'Ownership', label: 'Ownership', value: '', required: false },
    {
      name: 'AssignedBuildings',
      label: 'Building Assignment',
      value: '',
      AssignedBuildings: [],
      required: true,
    },
  ])
  const Email = data.find((v) => v.name === 'Email')?.value
  const Name = data.find((v) => v.name === 'Name')?.value
  const [showSend, setShowSend] = useState(false)

  let canSave = true

  const handleStateData = useCallback(
    (name, value, idx) => {
      setData((pre) => {
        // @ts-ignore
        pre[idx]['value'] = value
        if (name === 'Role' && value !== ThirdParty) {
          const index = pre.findIndex((v) => v.name === 'VendorId')
          pre[index]['value'] = ''
          pre[index]['VendorName'] = ''
        }
        if (name === 'Role') {
          setshowPermission(!!DEFAULT_PERMISSIONS[value])
          setPermissionSet(DEFAULT_PERMISSIONS[value] || { Contacts: false, Marketing: false, Reporting: false })
        }
        if (name === 'VendorId') {
          pre[idx]['VendorName'] = vendorOption.find((v) => v.id === value)?.VendorName
        }
        if (name === 'AssignedBuildings') {
          pre[idx]['AssignedBuildings'] = value.map((v) => v.id)
        }
        return [...pre]
      })
    },
    [vendorOption]
  )
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, idx: number) => {
      const { name, value } = event.target
      handleStateData(name, value, idx)
    },
    [vendorOption, handleStateData]
  )

  const onChangePhone = useCallback((phoneStr: string, idx: number) => {
    setData((pre) => {
      // @ts-ignore
      pre[idx]['value'] = phoneStr
      return [...pre]
    })
  }, [])

  const onSave = () => {
    setValidate(true)
    if (canSave) {
      const payload: Record<string, any> = {
        GroupId: gId,
        BuildingId: bId,
        ContactMemberId,
        ContactUserId,
        Contact: {},
        PermissionSet: permissionSet,
      }
      data.forEach((v: any) => {
        const { name, value } = v
        payload['Contact'][name] = value
        if (name === 'VendorId') {
          payload['Contact']['VendorName'] = v.VendorName || ''
        }
        if (name === 'AssignedBuildings' && portfolioTeam) {
          delete payload['Contact']['AssignedBuildings']
          payload['AssignedBuildings'] = v.AssignedBuildings
        }
      })
      let api = ContactMemberId ? editPropertyContact : addPropertyContact
      if (portfolioTeam) {
        api = ContactMemberId ? editPortfolioContact : addPortfolioContact
        delete payload.BuildingId
      }
      api<ContactsITF>(payload)
        .then((res) => {
          onClose(res)
        })
        .catch((err) => {
          SnackBar({ msg: err, type: 'error', duration: 3000 })
        })
    }
  }

  const onClickSend = () => {
    if (Name && isEmail(Email)) {
      setShowSend(true)
    } else {
      setValidate(true)
    }
  }

  const onSend = () => {
    sendPortalAccess({ ContactUserId, Email }).then(() => {
      setShowSend(false)
    })
  }

  useEffect(() => {
    if (state) {
      setPermissionSet(state?.PermissionSet)
      setshowPermission(!!DEFAULT_PERMISSIONS[state?.Role])
      setData((pre) => {
        pre.forEach((v) => {
          const { name } = v
          if (name === 'Name') {
            v.value = state?.FullName?.[language] || ''
            return
          }
          if (name === 'Title') {
            v.value = state?.Title?.[language] || ''
            return
          }
          if (name === 'AssignedBuildings') {
            v.AssignedBuildings = state?.AccessBuildingIds || []
            return
          }
          if (name === 'VendorId') {
            v.VendorName = state?.VendorName || ''
          }
          v.value = state[name] || ''
        })
        return [...pre]
      })
    }
  }, [state, bId])

  useEffect(() => {
    const params = {}
    if (survey) {
      Object.assign(params, { survey: true })
    }
    GetRoles(params).then((res: any) => setOption(res))
    if (!portfolioTeam) {
      getPropertyVendors({
        filter: JSON.stringify({ gId, bId }),
      }).then((res: any) => {
        setVendorOption(res?.Vendors)
      })
    }
    if (portfolioTeam) {
      getPropertyList({
        filter: JSON.stringify({ gId }),
        pagination: JSON.stringify({ page: 0, perPage: 9999 }),
      }).then((res: any) => {
        setState({
          propertyList: res.Properties,
        })
      })
    }
  }, [bId, gId, survey, portfolioTeam])
  const onClick = (event) => {
    event.stopPropagation()
  }
  const onChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setPermissionSet((prevState) => {
      // @ts-ignore
      return Object.assign({}, prevState, { [event.target.name]: checked })
    })
  }
  return (
    <Dialog open={true} disableEscapeKeyDown maxWidth="md" onClick={onClick}>
      <DialogTitle id="scroll-dialog-title">
        <Box className={classes.subTitle}>
          <Typography className={classes.title}>{ContactMemberId ? 'Edit Contact' : 'Create New Contact'}</Typography>
          <IconButton onClick={() => onClose?.()} style={{ padding: 0 }}>
            <Close htmlColor="#211F1F" />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent className={classes.box}>
        {data.map((v, i) => {
          const { name, label, required, value = '', AssignedBuildings } = v as any
          const isPhone = name === 'Phone'
          const isEmailType = name === 'Email'
          if (name === 'VendorId' && data.find((item) => item.name === 'Role')?.value !== ThirdParty) {
            return null
          }
          if (name === 'AssignedBuildings' && !portfolioTeam) return null
          let error = required && validate && !value
          if (required && !value) {
            canSave = false
          }
          let errorText = 'Required'
          if (value) {
            if ((isPhone && value.length !== 11) || (isEmailType && !isEmail(value))) {
              canSave = false
              if (validate) {
                error = true
                errorText = 'Format error'
              }
            }
          }

          if (isPhone) {
            return (
              <MobilePhone
                showFirst={false}
                index={i}
                key={name}
                {...v}
                disabled={disabledUpdate}
                error={error}
                errorText={errorText}
                onChangePhone={onChangePhone}
                className={classes.textField}
              />
            )
          }
          let options = option
          if (name === 'VendorId') {
            options = vendorOption
          }
          if (name === 'Ownership') {
            return (
              <Autocomplete
                key={i}
                disabled={disabledUpdate}
                value={OWNERSHIP.find((item) => item.value === value)}
                onChange={(e, newValue) => {
                  handleStateData(name, newValue?.value, i)
                }}
                options={OWNERSHIP}
                className={classes.textField}
                renderInput={(params) => <TextField {...params} label={label} />}
              />
            )
          }
          if (name === 'Role') {
            return (
              <Autocomplete
                key={i}
                disabled={disabledUpdate}
                value={value}
                inputValue={value || ''}
                onChange={(e, newValue) => {
                  handleStateData(name, newValue, i)
                }}
                options={options}
                className={classes.textField}
                renderInput={(params) => (
                  <TextField {...params} label={label} error={error} helperText={error ? errorText : ''} />
                )}
              />
            )
          }
          if (name === 'AssignedBuildings') {
            error = required && validate && AssignedBuildings.length === 0
            canSave = AssignedBuildings.length > 0
            return (
              <Autocomplete
                key={name}
                disableClearable
                onChange={(_, newValue) => handleStateData(name, newValue, i)}
                options={propertyList}
                multiple
                openOnFocus
                disableCloseOnSelect
                className={classes.textField}
                value={propertyList.filter((v) => AssignedBuildings.includes(v.id))}
                getOptionLabel={(option: any) => option?.Name?.en}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Building Assignment"
                    error={error}
                    helperText={error ? errorText : ''}
                  />
                )}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {option?.Name?.en}
                    </li>
                  )
                }}
                renderTags={(value) => {
                  if (value?.length > 0)
                    return <div className={classes.tag}>{value.map((v) => v?.Name?.en)?.join(', ')}</div>
                  return null
                }}
              />
            )
          }
          return (
            <TextField
              key={i}
              error={error}
              disabled={disabledUpdate}
              helperText={error ? errorText : ''}
              label={label}
              name={name}
              select={['Role', 'VendorId'].includes(name)}
              value={value}
              variant="outlined"
              className={classes.textField}
              SelectProps={{
                displayEmpty: true,
                classes: {
                  select: classes.select,
                },
                type: 'search',
              }}
              onChange={(e) => handleChange(e, i)}
            >
              {options.map((v) => {
                const key = v?.id || v
                const label = v?.VendorName || v
                return (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                )
              })}
            </TextField>
          )
        })}
        <span className={classes.textField} />
        {showPermission && (
          <Box sx={{ width: '100%', padding: '0 16px 16px' }}>
            <Typography className={classes.smallTitle}>Permissions</Typography>
            <Box sx={{ display: 'flex', gap: '24px' }}>
              <FormControlLabel
                classes={{ root: classes.formSwitch, label: classes.formLabel }}
                control={
                  <Switch color="primary" checked={permissionSet?.Reporting || false} onChange={onChangeSwitch} />
                }
                label="Reporting"
                name="Reporting"
                labelPlacement="start"
              />
              <FormControlLabel
                name="Contacts"
                classes={{ root: classes.formSwitch, label: classes.formLabel }}
                control={
                  <Switch color="primary" checked={permissionSet?.Contacts || false} onChange={onChangeSwitch} />
                }
                label="Contacts"
                labelPlacement="start"
              />
              <FormControlLabel
                classes={{ root: classes.formSwitch, label: classes.formLabel }}
                control={
                  <Switch color="primary" checked={permissionSet?.Marketing || false} onChange={onChangeSwitch} />
                }
                label="Marketing"
                name="Marketing"
                labelPlacement="start"
              />
            </Box>
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ padding: '16px' }}>
        {!!ContactMemberId && (
          <>
            <Telegram htmlColor="#466CB5" sx={{ margin: '0 8px' }} />
            <Typography className={classes.access} onClick={onClickSend}>
              Send Portal Access
            </Typography>
          </>
        )}
        <Button variant="outlined" color="primary" style={{ marginRight: 16, width: 90 }} onClick={() => onClose?.()}>
          cancel
        </Button>
        <Button disabled={disabledUpdate} variant="contained" color="primary" style={{ width: 90 }} onClick={onSave}>
          Save
        </Button>
      </DialogActions>

      {showSend && (
        <Dialog open={showSend} disableEscapeKeyDown maxWidth="xs" onClick={onClick}>
          <DialogTitle id="scroll-dialog-title">
            <Box className={classes.subTitle}>
              <Typography className={classes.title}>Send Portal Access</Typography>
              <IconButton onClick={() => setShowSend(false)} style={{ padding: 0 }}>
                <Close htmlColor="#211F1F" />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Typography>
              Please confirm you want to send {Name} at {Email} portal access
            </Typography>
          </DialogContent>
          <DialogActions sx={{ padding: '16px' }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: 16, width: 90 }}
              onClick={() => setShowSend(false)}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" style={{ width: 90 }} onClick={onSend}>
              Send
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  )
}

export default ContactCreate
