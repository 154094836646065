import { stringify } from 'query-string'
import { fetchUtils, DataProvider } from 'ra-core'
import { getAuthToken } from '../common/auth'
import { ReportTypeEnum } from '@constant/report'

type HTTPClientPromiseReturn = {
  headers: Headers
  json: any
}

const client = async (url: any, options: fetchUtils.Options = {}) => {
  const userToken = await getAuthToken()
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
      UserToken: userToken,
    })
  }
  //@ts-ignore
  options.headers.set('UserToken', userToken)
  return fetchUtils.fetchJson(url, options)
}

const Marketing_Report_APIMap: Record<string, string> = {
  'vacancy-1': 'Vacancy/GetVacancyLeads',
  'fieldevent-1': 'Event/GetRegistrants',
  'purchase-1': 'Purchase/GetPurchases',
  'communication-1': 'Communication/GetCommunications',
  'fieldmarketing-1': 'LeadCapture/GetLeadEventInfos',
  'fieldmarketing-2': 'LeadCapture/GetLeadCaptures',
  'maintenance-1': 'Maintenance/GetMaintenanceRequests',
  'movein-1': 'Maintenance/GetMaintenanceRequests',
  'moveout-1': 'Maintenance/GetMaintenanceRequests',
  'tenantevent-1': 'LeadCapture/GetCheckInEvents',
  'tenantevent-2': 'LeadCapture/GetCheckIns',
  'leadCapture-1': 'LeadCapture/GetLeadEventInfos',
  'leadCapture-2': 'LeadCapture/GetLeadCaptures',
  'eVRequest-1': 'LeadCapture/GetLeadEventInfos',
  'eVRequest-2': 'LeadCapture/GetLeadCaptures',
  'roomRental-1': 'LeadCapture/GetLeadEventInfos',
  'roomRental-2': 'LeadCapture/GetLeadCaptures',
}

const Subscription_Report_APIMap: Record<string, string> = {
  vacancy: 'GetVacancyReport',
  fieldmarketing: 'GetFieldMarketingReport',
  event: 'GetEventReport',
}

const DataKey_MAP = {
  'vacancy-1': 'VacancyLeads',
  'fieldevent-1': 'Registrants',
  'purchase-1': 'Purchases',
  'communication-1': 'Communications',
  'fieldmarketing-1': 'LeadEventInfos',
  'fieldmarketing-2': 'LeadCaptures',
  'maintenance-1': 'MaintenanceRequests',
  'event-1': 'Registrants',
  'tenantevent-1': 'LeadEventInfos',
  'tenantevent-2': 'CheckIns',
  'movein-1': 'MaintenanceRequests',
  'moveout-1': 'MaintenanceRequests',
  'leadCapture-1': 'LeadEventInfos',
  'leadCapture-2': 'LeadCaptures',
  'eVRequest-1': 'LeadEventInfos',
  'eVRequest-2': 'LeadCaptures',
  'roomRental-1': 'LeadEventInfos',
  'roomRental-2': 'LeadCaptures',
}

const withDictionary_Type = {
  'fieldmarketing-2': ['ColumnNames'],
  'leadCapture-2': ['ColumnNames'],
}

const apiKeyType: Record<string, string> = {
  'leadCapture-1': ReportTypeEnum.LeadCapture,
  'eVRequest-1': ReportTypeEnum.EVRequest,
  'roomRental-1': ReportTypeEnum.RoomRental,
}

let preTableName = ''

const getData = (client, countHeader, url, tableName, totleFromHead = true) => {
  return client(url, {
    method: 'GET',
  }).then(({ headers, json, body }) => {
    const dataKey = DataKey_MAP[tableName]
    if (!json?.data) {
      throw new Error(body)
    }
    if (withDictionary_Type[tableName]) {
      const dictionaryObj = withDictionary_Type[tableName].reduce((obj, item) => {
        if (item in json.data) {
          obj[item] = json.data[item]
        }
        return obj
      }, {})
      json.data[dataKey] = json.data[dataKey].map((i: any) => {
        return { ...i, ...dictionaryObj }
      })
    }
    return {
      data: json.data[dataKey],
      total: totleFromHead
        ? countHeader === 'Content-Range'
          ? parseInt((headers.get('content-range') || '').split('/').pop() || '', 10)
          : parseInt(headers.get(countHeader.toLowerCase()) || '')
        : json.data.Total,
    }
  })
}

const dataProvider = (apiUrl: string, httpClient = client, countHeader: string = 'Content-Range'): DataProvider => ({
  //@ts-ignore
  getList: (resource, params) => {
    const converParams = params
    console.log('getList', resource, params)
    const { page, perPage } = converParams.pagination
    const isQrSupportReport = resource === 'qrSupportReport'
    const { field, order } = converParams.sort
    const { gId, bId } = converParams.filter
    const { tableName, getSubList, ...orgFilter } = converParams.filter
    const rangeStart = (page - 1) * perPage
    const rangeEnd = page * perPage - 1
    const query = {
      pagination: JSON.stringify({ page: page - 1, perPage }),
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify(orgFilter),
    }
    if (field) {
      Object.assign(query, { sort: JSON.stringify([isQrSupportReport ? 'CreatedDate' : field, order]) })
    }
    let url = `${apiUrl}/${resource}/list?${stringify(query)}`

    switch (resource) {
      case 'destination':
        return Promise.resolve({
          data: [
            { id: 'programming', name: 'Programming' },
            { id: 'lifestyle', name: 'Lifestyle' },
            { id: 'photography', name: 'Photography' },
          ],
          total: 3,
        })
      case 'report':
        url = `${apiUrl}/csm/Report/${Subscription_Report_APIMap[tableName]}?${stringify(query)}`
        return new Promise((r) =>
          getData(httpClient, countHeader, url, tableName + '-' + (getSubList ? 2 : 1), false).then((data) => r(data))
        )
      case 'subscription':
      case 'subscriptions':
        url = `${apiUrl}/csm/Subscription/GetSubscriptions?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: json.data.Subscriptions,
            total: json.data.Total,
          }
        })
      case 'contactList':
        url = `${apiUrl}/csm/Entity/GetPropertyContacts?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: json.data.Contacts,
            total: json.data.Total,
          }
        })
      case 'portfolioContacts':
        url = `${apiUrl}/csm/Portfolio/GetPortfolioContacts?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: json.data.Contacts,
            total: json.data.Total,
          }
        })
      case 'properties':
        url = `${apiUrl}/csm/Entity/GetPropertyList?${stringify(query)}`
        // url = `${apiUrl}/csm/Subscription/GetSubscriptions?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: json.data.Properties,
            total: json.data.Total,
          }
        })
      case 'qrcodelist':
      case 'myqrcode': {
        const { ModifiedDate, ...filter } = orgFilter || {}
        if (ModifiedDate) {
          Object.assign(filter, ModifiedDate)
        }
        query.filter = JSON.stringify(filter)
        url = `${apiUrl}/csm/QrCode/GetQrCodesByGroupId?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json }) => {
          return {
            data: json.data.QrCodes.map((i) => {
              if (!i.Unit) {
                i.Unit = 'N/A'
              }
              return i
            }),
            total: json.data.Total,
          }
        })
      }
      case 'twoQRLibrary': {
        const { ...filter } = orgFilter || {}
        query.filter = JSON.stringify(filter)
        url = `${apiUrl}/csm/QrCode/GetQrPairs?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json }) => {
          return {
            data: json.data.QrPairs || [],
            total: json.data.Total,
          }
        })
      }
      case 'reportMarketing':
      case 'marketing': {
        const filter = Object.assign({}, orgFilter)
        const apiKey = tableName + '-' + (getSubList ? 2 : 1)
        if (['leadCapture-1', 'eVRequest-1', 'roomRental-1'].includes(apiKey)) {
          Object.assign(filter, { type: apiKeyType[apiKey] })
        }
        if (tableName === 'qrSupportReport') {
          return new Promise((resolve) => resolve({ data: [], total: 0 }))
        }
        if (preTableName !== apiKey) {
          preTableName = apiKey
          let sort = ['CreatedDate', 'DESC']
          if (apiKey === 'roomRental-2') {
            sort = ['RentalDate', 'DESC']
          }
          Object.assign(query, { sort: JSON.stringify(sort) })
        }
        url = `${apiUrl}/csm/${Marketing_Report_APIMap[apiKey]}?${stringify(
          Object.assign({}, query, { filter: JSON.stringify(filter) })
        )}`
        return new Promise((r) =>
          getData(httpClient, countHeader, url, tableName + '-' + (getSubList ? 2 : 1)).then((data) => r(data))
        )
      }
      case 'vacancyLead':
        url = `${apiUrl}/csm/Vacancy/GetVacancyLeads?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: json.data.VacancyLeads,
            total: json.data.Total,
          }
        })
      case 'leadCapture':
        url = `${apiUrl}/csm/LeadCapture/GetLeadEventInfos?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: json.data.LeadEventInfos,
            total: json.data.Total,
          }
        })
      case 'subLeadCapture':
        url = `${apiUrl}/csm/LeadCapture/GetLeadCaptures?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          if (json.data?.ColumnNames?.length && json.data.Total) {
            json.data.LeadCaptures[0].ColumnNames = json.data.ColumnNames
          }
          return {
            data: json.data.LeadCaptures,
            total: json.data.Total,
          }
        })
      case 'reportTenantExpirenceEvent':
      case 'tenantExpirenceEvent':
        url = `${apiUrl}/csm/${Marketing_Report_APIMap[tableName + '-' + (getSubList ? 2 : 1)]}?${stringify(query)}`
        return new Promise((r) =>
          getData(httpClient, countHeader, url, tableName + '-' + (getSubList ? 2 : 1)).then((data) => r(data))
        )
      case 'reportPropertyReport':
      case 'propertyReport':
      case 'operations':
        url = `${apiUrl}/csm/${Marketing_Report_APIMap[tableName + '-' + (getSubList ? 2 : 1)]}?${stringify(query)}`
        return new Promise((r) =>
          getData(httpClient, countHeader, url, tableName + '-' + (getSubList ? 2 : 1)).then((data) => r(data))
        )
      case 'importQRCode':
        // url = `${apiUrl}/csm/Provision/GetProvisionList?${stringify(query)}`
        url = `${apiUrl}/csm/Provision/GetProvisionList?${stringify(converParams.filter)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json }) => {
          return {
            data: json.data.map((v, i) => {
              v.id = v.id || v['_id'] || i
              return v
            }),
            total: json.data?.length,
          }
        })
      case 'qrSupportReport':
        url = `${apiUrl}/csm/Support/GetSupports?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: json.data.Supports,
            total: json.data.Total,
          }
        })
      case 'equipmentLibrary':
        url = `${apiUrl}/csm/Equipment/GetEquipments?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: json.data.Equipments,
            total: json.data.Total,
          }
        })
      case 'inspectionReport':
        url = `${apiUrl}/csm/Inspection/GetInspectionTemplates?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: json?.data?.InspectionTemplates || [],
            total: json?.data?.Total || 0,
          }
        })
      case 'equipmentQuestion':
        url = `${apiUrl}/csm/Equipment/GetStopEquipmentTypes?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: json.data.map((v) => {
              v.id = v.Type
              return v
            }),
            total: json.data.length,
          }
        })
      case 'vendorList':
        url = `${apiUrl}/csm/Vendor/GetPropertyVendors?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: json.data.Vendors,
            total: json.data.Total,
          }
        })
      case 'inspectionLibrary':
        url = `${apiUrl}/csm/Inspection/GetInspectionTemplates?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: (json.data.InspectionTemplates || []).map((item) => ({ ...item, id: item.id || item._id })),
            total: json.data.Total,
          }
        })
      case 'installationRound':
        url = `${apiUrl}/csm/Round/GetInstallationRoundSumary?gId=${gId}&bId=${bId}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: (json.data.Inspections || []).map((item) => ({ ...item, id: item.EquipmentInspectionId })),
            total: json.data.Total,
          }
        })
      case 'tenantLibrary':
        url = `${apiUrl}/csm/Tenant/GetTenantList?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: (json.data.Tenants || []).map((item) => ({ ...item, id: item.acId })),
            total: json.data.Total,
          }
        })
      case 'residentsLibrary':
        url = `${apiUrl}/csm/Tenant/GetTenantList?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: (json.data.Tenants || []).map((item) => ({ ...item, id: item.acId })),
            total: json.data.Total,
          }
        })
      case 'digitalProofReport':
        url = `${apiUrl}/csm/Survey/GetCarouselList?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: (json.data.Carousels || []).map((item) => ({ ...item, id: item._id })),
            total: json.data.Total,
          }
        })
      case 'pmLibrary':
        url = `${apiUrl}/csm/WorkOrder/GetPMWorkOrders?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: json.data.WorkOrders,
            total: json.data.Total,
          }
        })
      case 'printProof': {
        const p = {
          gId,
          bId,
          skip: (page - 1) * perPage,
          take: perPage,
        }
        const { qrtype } = converParams.filter
        if (qrtype) {
          Object.assign(p, { qrtype })
        }
        url = `${apiUrl}/csm/PrintManagement/GetPrintSettingsOnBuilding?${stringify(p)}`

        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          const res = json.data || {}
          return {
            data: (res.PrintSettings || []).map((item) => ({ ...item, id: item.Location + item.Type })),
            total: res.Total,
          }
        })
      }
      case 'mechanicalSpaceLibrary':
        url = `${apiUrl}/csm/MechanicalSpace/GetMechanicalSpaces?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: (json.data.Spaces || []).map((item) => ({ ...item, id: item._id })),
            total: json.data.Total,
          }
        })
      case 'renewalReport':
        url = `${apiUrl}/csm/Renewal/GetRenewals?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: json.data.Renewals || [],
            total: json.data.Total,
          }
        })
      case 'moveoutReport':
        url = `${apiUrl}/csm/Move/GetMoveOuts?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: (json.data.Moves || []).map((item) => ({ ...item, id: item._id })),
            total: json.data.Total,
          }
        })
      case 'printLibrary':
        url = `${apiUrl}/csm/PrintManagement/GetGlobalPrintSetting?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          return {
            data: (json.data.Prints || []).map((item) => ({ ...item, id: item._id })),
            total: json.data.Total || 0,
          }
        })
      case 'printHubLibrary':
        url = `${apiUrl}/csm/QrCode/GetGlobalQrHubs?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json, body }) => {
          let maxButtons = 0
          const data = (json.data.QrHubs || []).map((item) => {
            const { Items = [] } = item
            maxButtons = Math.max(maxButtons, Items?.length || 0)
            const buttonsObj = Items.reduce((data, currentValue, currentIndex) => {
              const key = `Button${currentIndex + 1}`
              return Object.assign(data, { [key]: currentValue.Name })
            }, {})
            return { ...item, id: item._id, ...buttonsObj }
          })
          if (data[0]) {
            data[0].ButtonColumns = new Array(maxButtons).fill(0).map((item, index) => {
              return `Button${index + 1}`
            })
          }
          return {
            data,
            total: json.data.Total || 0,
          }
        })
      case 'printQRLibrary': {
        const obj: Record<string, string> = {}
        // eslint-disable-next-line no-case-declarations
        const { filter, ...extraFilter } = orgFilter || {}
        filter?.forEach((v) => {
          //@ts-ignore
          obj[v.parent] = v.value
        })
        Object.assign(extraFilter, obj)
        query.filter = JSON.stringify(extraFilter)
        url = `${apiUrl}/csm/QrCode/GetGlobalQrCodes?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        })
          .then(({ json, body }) => {
            return {
              data: json.data.QrCodes || [],
              total: json.data.Total || 0,
            }
          })
          .catch((res) => {
            return { data: [], total: 0 }
          })
      }
      case 'masterNotepadContent': {
        url = `${apiUrl}/csm/Survey/GetCarouselNotes?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        })
          .then(({ json, body }) => {
            return {
              data: (json.data.CarouselNotes || []).map((v) => {
                v.id = v._id + '-' + v.CreatedDate
                return v
              }),
              total: json.data.Total || 0,
            }
          })
          .catch((res) => {
            return { data: [], total: 0 }
          })
      }
      case 'masterNotepadSign': {
        url = `${apiUrl}/csm/PrintManagement/GetPrintProofNotes?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        })
          .then(({ json, body }) => {
            return {
              data: (json.data.PrintProofNotes || []).map((v) => {
                v.id = v._id + '-' + v.CreatedDate
                return v
              }),
              total: json.data.Total || 0,
            }
          })
          .catch((res) => {
            return { data: [], total: 0 }
          })
      }
      case 'surveyList': {
        url = `${apiUrl}/csm/Survey/GetSurveyList?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        })
          .then(({ json, body }) => {
            return {
              data: json.data.Builders || [],
              total: json.data.Total || 0,
            }
          })
          .catch((res) => {
            return { data: [], total: 0 }
          })
      }
      case 'staffCheckIn': {
        url = `${apiUrl}/csm/SupplierCheck/GetStaffCheckRecordSummaries?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        })
          .then(({ json, body }) => {
            return {
              data: json.data.StaffChecks || [],
              total: json.data.Total || 0,
            }
          })
          .catch((res) => {
            return { data: [], total: 0 }
          })
      }
      case 'buildSolutionList': {
        url = `${apiUrl}/csm/Solution/GetBuildingSolutionBuilder?bId=${bId}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json }) => {
          return {
            data: json.data,
            total: json.data.Total,
          }
        })
      }
      case 'reportBuilderSubscriptions': {
        url = `${apiUrl}/csm/Report/GetReportBuilderSubscriptions?${stringify(query)}`
        return httpClient(url, {
          method: 'GET',
        }).then(({ json }) => {
          return {
            data: json.data.Subscriptions,
            total: json.data.Total,
          }
        })
      }
      default:
        return {
          data: [],
          total: 0,
        }
    }
  },
  //@ts-ignore
  create: async (resource, params) => {
    switch (resource) {
      case 'admin/projects':
        break
      default:
        return httpClient(`${apiUrl}/csm/${resource.charAt(0).toUpperCase() + resource.slice(1)}/Create`, {
          method: 'POST',
          body: JSON.stringify(params.data),
        }).then(({ json }) => ({
          data: { ...params.data, id: json.id },
        }))
    }
  },

  getOne: (resource, params) => {
    switch (resource) {
      case 'admin/template/modules/instance':
        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
          data: json,
        }))
      case 'admin/template/projects/instance':
        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
          data: json,
        }))
      default:
        return httpClient(`${apiUrl}/${resource}/list/${params.id}`).then(({ json }) => ({
          data: json,
        }))
    }
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    let url = `${apiUrl}/${resource}/list?${stringify(query)}`

    switch (resource) {
      case 'projects':
      case 'modules':
      case 'tasks':
        url = `${apiUrl}/${resource}/all/list?${stringify(query)}`
        return httpClient(url).then(({ json }) => ({ data: json }))
      default:
        return httpClient(url).then(({ json }) => ({ data: json }))
    }
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort

    const rangeStart = (page - 1) * perPage
    const rangeEnd = page * perPage - 1

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    }
    let url = `${apiUrl}/${resource}/list?${stringify(query)}`
    const options =
      countHeader === 'Content-Range'
        ? {
            // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
            headers: new Headers({
              Range: `${resource}=${rangeStart}-${rangeEnd}`,
            }),
          }
        : {}

    switch (resource) {
      case 'tasks':
      case 'modules':
        url = `${apiUrl}/${resource}/all/list?${stringify(query)}`

        return httpClient(url, options).then(({ headers, json }: HTTPClientPromiseReturn) => {
          if (!headers.has(countHeader)) {
            throw new Error(
              `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
            )
          }
          return {
            data: json,
            total:
              countHeader === 'Content-Range'
                ? parseInt((headers.get('content-range') || '').split('/').pop() || '', 10)
                : parseInt(headers.get(countHeader.toLowerCase()) || ''),
          }
        })
      default:
        return httpClient(url, options).then(({ headers, json }: HTTPClientPromiseReturn) => {
          if (!headers.has(countHeader)) {
            throw new Error(
              `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
            )
          }
          return {
            data: json,
            total:
              countHeader === 'Content-Range'
                ? parseInt((headers.get('content-range') || '').split('/').pop() || '', 10)
                : parseInt(headers.get(countHeader.toLowerCase()) || ''),
          }
        })
    }
  },

  update: (resource, params) => {
    // let formData = new FormData()
    switch (resource) {
      case 'notifications/readall':
        return httpClient(`${apiUrl}/${resource}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }).then(({ json }) => Promise.resolve({ data: { id: '' } }))
      case 'notifications/read':
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }).then(({ json }) => Promise.resolve({ data: { id: '' } }))
      // case 'admin/projects':
      //     formData = convertProjectToFormData(params.data as IProject)

      //     return httpClient(`${apiUrl}/${resource}/list/${params.id}`, {
      //         method: 'PUT',
      //         body: formData,
      //     }).then(({ json }) => ({
      //         data: { ...params.data, id: json.id },
      //     }))
      // case 'proceeding/tasks/upload':
      // case 'proceeding/tasks/review':
      //     formData = convertFileUpload(params.data as fileBody)

      //     return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      //         method: 'PUT',
      //         body: formData,
      //     }).then(({ json }) => Promise.resolve({ data: { id: '' } }))
      case 'proceeding/tasks/complete':
      case 'proceeding/tasks/approve':
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }).then(({ json }) => Promise.resolve({ data: { id: '' } }))
      default:
        return httpClient(`${apiUrl}/${resource}/list/${params.id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }))
    }
  },

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/list/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/list/${params.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    }).then(({ json }) => ({ data: json })),

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/list/${id}`, {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'text/plain',
          }),
        })
      )
    ).then((responses) => ({
      data: responses.map(({ json }) => json.id),
    })),
})

export default dataProvider
