import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { DEFAULT_DATE_RANGE_TODAY } from '@/global'
import { Box, Typography } from '@mui/material'
import CommonCard from '@component/Card/CommonCard'
import { formatNumber, formatSymbolNumber } from '@utils/common'
import Images from '@assets/images'
import { getCustomerFeedbackSummariesList, getCustomerFeedbackSummary } from '@api'
import useSetState from '@hooks/useSetState'
import TableList, { TableConfig } from '@component/Tabs/TableList'
import Filter from '@component/Filter/Filter'
import CustomDateRange from '@component/DateSelect/CustomDateRange'
import { DateRange } from '@mui/x-date-pickers-pro'
import { Dayjs } from 'dayjs'

const QRTYPE = [
  {
    value: 'QrType',
    label: 'Qr Type',
    child: [
      { value: 'Maintenance', label: 'Service Request' },
      { value: 'SupplierCheckin', label: 'Supplier Check in' },
      { value: 'ActivitySchedule', label: 'Re-occurring Repair' },
      { value: 'Inventory', label: 'Inventory' },
      { value: 'MoveIn', label: 'Move In' },
      { value: 'MoveOut', label: 'Move Out' },
      { value: 'BathroomService', label: ' Bathroom Service' },
      { value: 'LadderSafety', label: 'Ladder Safety' },
    ],
  },
  {
    value: 'RoundType',
    label: 'Round Type',
    child: [
      { value: 'Cleaning', label: 'Cleaning' },
      { value: 'Engineering', label: 'Engineering' },
      { value: 'Inspection', label: 'Inspection' },
      { value: 'PreventativeMaintenance', label: 'Preventative Maintenance' },
    ],
  },
]

const ROLE = [
  { value: 'Engineer', label: 'Engineer' },
  { value: 'Tenant', label: 'Tenant' },
  { value: 'Supplier', label: 'Supplier' },
  { value: 'Resident', label: 'Resident' },
  { value: 'GM', label: 'GM' },
]
const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: '#F5F5F5',
    flex: 1,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    marginTop: -100,
  },
  header: {
    display: 'flex',
    padding: '18px 0',
    justifyContent: 'flex-end',
  },
  card: {
    borderRadius: 8,
    marginBottom: 24,
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
    padding: '24px 24px 36px',
    backgroundColor: 'white',
  },
  topCard: {
    display: 'flex',
    marginTop: 22,
    marginBottom: 24,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
  },
  title: {
    fontSize: 20,
  },
}))

const COMMON: Array<TableConfig<any>> = [
  {
    label: 'Feedback Count',
    align: 'left',
    source: 'Count',
    width: 245,
  },
  {
    label: 'CSAT',
    source: 'CSAT',
  },
  {
    label: 'CES',
    source: 'CES',
  },
  {
    label: 'NPS',
    source: 'NPS',
    align: 'center',
    convertItem: (item) => item.NPS,
  },
]
const QR_TYPE_CONFIG: Array<TableConfig<any>> = [
  {
    label: 'QR / Round Type',
    source: 'DisplayText',
    width: 245,
    convertItem: (item) => item?.SubTypeDisplayText || item.DisplayText,
  },
  ...COMMON,
]
const ROLE_CONFIG: Array<TableConfig<any>> = [
  {
    label: 'Role',
    source: 'Role',
    width: 245,
  },
  ...COMMON,
]
interface SummariesListProps {
  range: DateRange<Dayjs>
  gId?: string
  bId?: string
  title: string
  isQr?: boolean
}

const SummariesList: React.FC<SummariesListProps> = ({ range, gId, title, isQr, bId }) => {
  const classes = useStyles()
  const [data, setData] = useSetState<{ summariesList: any[]; list?: any[]; filter: IOption[] }>({
    summariesList: [],
    filter: [],
  })
  const filters = isQr ? QRTYPE : ROLE
  const { summariesList, list, filter } = data
  const fetchFeedbackSummariesList = useCallback(async () => {
    const params = { gId, bId }
    if (range?.length === 2 && range[0]?.isValid() && range[1]?.isValid()) {
      Object.assign(params, { startDate: range[0].valueOf(), endDate: range[1].valueOf() })
    }
    if (isQr) {
      Object.assign(params, { scope: 'QrType', qrType: 'All', role: 'All' })
    } else {
      Object.assign(params, { scope: 'Role', role: 'All', qrType: 'All' })
    }
    const result = await getCustomerFeedbackSummariesList<{ Summaries: any[] }>(params)
    setData({ summariesList: result?.Summaries || [], list: result?.Summaries || [] })
  }, [range, gId, bId, isQr, setData])
  const onChangeFilter = useCallback(
    (value: Array<IOption>) => {
      setData({ filter: value })
    },
    [setData]
  )
  useEffect(() => {
    if (!filter || (Array.isArray(filter) && filter.length === 0)) {
      return setData({ list: summariesList })
    }
    const nextList = summariesList.filter((item) => {
      if (isQr) return filter.findIndex((data) => [item.QrType, item?.SubType].includes(data.value)) > -1
      return filter.findIndex((data) => data.value === item.Role) > -1
    })
    setData({ list: nextList })
  }, [isQr, setData, summariesList, filter])

  useEffect(() => {
    fetchFeedbackSummariesList()
  }, [fetchFeedbackSummariesList])
  return (
    <Box className={classes.card}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>{title}</Typography>
        <Filter filters={filters} onChange={onChangeFilter} isMulti={isQr} />
      </div>
      <TableList config={isQr ? QR_TYPE_CONFIG : ROLE_CONFIG} data={list} />
    </Box>
  )
}
const InAppFeedbackReport: React.FC = () => {
  const classes = useStyles()
  const { selectedPropertyId: bId, selectedGroupId: gId } = useSelector((state) => state.profile)
  const [data, setData] = useSetState<{ top: any; range: any }>({
    top: null as any,
    range: DEFAULT_DATE_RANGE_TODAY,
  })
  const { range, top } = data
  const fetchCustomerFeedbackSummary = useCallback(async () => {
    const params = { qrType: 'All' }
    if (range?.length === 2 && range[0]?.isValid() && range[1]?.isValid()) {
      Object.assign(params, { startDate: range[0].valueOf(), endDate: range[1].valueOf() })
    }
    if (bId) Object.assign(params, { bId })
    if (gId) Object.assign(params, { gId })
    const result = await getCustomerFeedbackSummary(params)
    setData({ top: result })
  }, [range, bId, gId, setData])
  useEffect(() => {
    fetchCustomerFeedbackSummary()
  }, [fetchCustomerFeedbackSummary])

  useEffect(() => {
    localStorage.setItem(
      'DashboardDate',
      JSON.stringify({
        StartDate: range[0]?.valueOf(),
        EndDate: range[1]?.valueOf(),
      })
    )
  }, [range])

  return (
    <div className={classes.paper}>
      <div className={classes.header}>
        <CustomDateRange value={range} onChange={(value) => setData({ range: value })} label="Date Range" />
      </div>
      <div className={classes.topCard}>
        <CommonCard
          label="Average CSAT"
          value={formatNumber(top?.CSAT)}
          loading={!top}
          desc="Out of 5"
          icon={Images.star}
        />
        <CommonCard isPercent value={top?.CES / 7} valueText={top?.CES || '0'} label="Average CES" loading={!top} />
        <CommonCard label="NPS Score" value={formatSymbolNumber(top?.NPS || 0)} loading={!top} icon={Images.thumbUp} />
      </div>
      <SummariesList title="QR Report" range={range} gId={gId} bId={bId} isQr />
      <SummariesList title="Role Report" range={range} gId={gId} bId={bId} />
    </div>
  )
}

export default InAppFeedbackReport
