import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, FormControl, Grid, InputLabel, MenuItem } from '@mui/material'
import { Select, Tab, Tabs, TextField, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import CustomTextField from '@component/Form/CustomTextField'
import 'sanity-mobile-preview/dist/index.css'
import { getEntityTypes, getQrCodeDetail, SaveEntity } from '@api/index'
import SnackBar from '@component/SnackBar'
import { QRLimit, ROUND_EQUIPMENT_TYPE, INSPECTION_TYPE, TAG_TYPE } from '@constant/qrcode'
import Autocomplete from '@mui/material/Autocomplete'
import { QRCodConfigContextValue, QRCodeConfigEnum, QRCodeFunctionType, QRCodeType, QRStatusType } from '../types'
import { FormDataContextProvider } from '../context/FormDataContext'
import MobileFrame from '../components/MobileFrame/MobileFrame'
import BackButton from '@component/BackButton/BackButton'
import { DynamicComponentsMaps } from './DynamicForm'
import { QRCodeProvider } from './QRCodeProvider'
import { PDF_MAP } from '@constant/app'
import { CommunicationMockData } from '@constant/mock'
import { EntityITF, PropertyInfo, QrCodeInfo, QRType, Template } from '@/types/entity'
import EditPanelContextProvider, { EditPanelContext } from '@context/EditPanelContext'
import EditAllPanel from '@component/EditPanel/EditAllPanel'
import { useGetQRTypes } from '@hooks/useGetQRTypes'
import { MemoizedQRConfig } from './Inner'
import { getTenantList } from '@api'
import { useSelector } from 'react-redux'
import PrintKit from '@component/QRCode/PrintKit'
import PickADesign from '@component/QRCode/PickADesign'
import { handlerHub, isCanSavaQr } from '@/utils'
import useSetState from '@hooks/useSetState'
import { usePermissions } from 'react-admin'
import { ExpandMore } from '@mui/icons-material'
import { useStyles } from './BuilderQr'
import { useDebouncedCallback } from 'use-debounce'
import { Loading } from 'ra-ui-materialui'
import { useLocation } from 'react-router'

interface TabPanelProps {
  children?: React.ReactNode
  className: any
  index: number
  value: number
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      style={value === index ? {} : { width: 0, height: 0, overflow: 'hidden' }}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box display="flex" sx={{ p: 1, width: '100%', flexDirection: 'column', boxSizing: 'border-box' }}>
        <>{children}</>
      </Box>
    </div>
  )
}

const QRCodeEdit = () => {
  const location = useLocation()
  const { qrId: qrIdP, groupId, bId } = useParams<Record<string, string>>()
  const { qrName, type } = useMemo(() => location.state || {}, [location.state])

  const classes = useStyles()
  const navigate = useNavigate()
  const [qrId, setQrId] = React.useState(qrIdP || '')

  const isEdit = !!qrId
  const [qrInfo, setQrInfo] = useState<QrCodeInfo>()
  const Type = (qrInfo?.Type || '') as QRCodeFunctionType
  const { permissions: { GlobalManageQrCode } = {} } = usePermissions()
  const { selectedPropertyName } = useSelector((state) => state.profile)
  const { panelData, editPanelIndex = -1 } = useContext(EditPanelContext)
  const currentGroupId = groupId
  const currentPropertyId = qrInfo?.BuildingId
  const currentPropertyName = qrInfo?.Building?.Name?.en || selectedPropertyName
  const [NameEn, setNameEn] = React.useState('')
  const [tabId, setTabId] = React.useState(1)
  const [template, setTemplate] = useState<Template | null | undefined>(null)
  const [qrType, setQRType] = useState<QRType | null>()
  const functionType = qrType?.FunctionalityType || Type
  const isHub = functionType === QRCodeFunctionType.QrHub
  const [types, setTypes] = useState<Record<string, any>[]>([])
  const [state, setState] = useSetState<Record<string, any>>({ TenantList: [], TagType: TAG_TYPE[0].value })
  const templateId = template?.acId || template?.TemplateId
  const isRound = functionType === QRCodeFunctionType.EquipmentInspection || type === QRLimit.Round
  const isCustomerPortal = !GlobalManageQrCode || type === 'CustomerPortal'
  const isCustomerQr = qrInfo?.SignId?.startsWith('D')
  const isInspection = functionType === QRCodeFunctionType.Inspection || type === QRLimit.Inspection
  const hiddenEntity =
    (!!functionType &&
      [
        QRCodeFunctionType.CustomerFeedback,
        QRCodeFunctionType.Inventory,
        QRCodeFunctionType.ActivitySchedule,
        QRCodeFunctionType.Permit,
      ].includes(functionType)) ||
    isCustomerPortal
  const isBathroomService = functionType === QRCodeFunctionType.BathroomService
  const isMechanicalSpace = functionType === QRCodeFunctionType.MechanicalSpace
  // @ts-ignore
  const { name = '', MechanicalSpace, EquipmentType, StopType = 'Equipment', InspectionType, TagType } = state
  const { Accessible = '', TenantId = '', TenantName = '', TenantList = [], Skip1FA = false } = state
  const [entity, setEntity] = useState<EntityITF>({
    Floor: '',
    Type: '',
    Unit: '',
  })
  const qrCodeOptions = useRef<{ [key: string]: string | boolean }>({})
  const isBuilding = useMemo(() => entity?.Type === 'Building', [entity?.Type])

  const { limit, qrNameLabel } = useMemo(() => {
    const specialQr = { limit: QRLimit.QrCode, qrNameLabel: 'QR Name' }
    if (isRound) {
      specialQr.limit = QRLimit.Round
      specialQr.qrNameLabel = 'QR Name /Equipment Name'
    }
    if (isInspection) {
      specialQr.limit = QRLimit.Inspection
      specialQr.qrNameLabel = 'Inspection Name'
    }
    if (isMechanicalSpace) {
      specialQr.limit = QRLimit.Round
      specialQr.qrNameLabel = 'Mechanical Name'
    }
    return specialQr
  }, [isRound, isInspection, isMechanicalSpace])
  const { data: qrTypeList } = useGetQRTypes(limit, bId)
  const { showMobileFunctionalityType, showQrType } = useMemo(() => {
    const isHub = functionType === QRCodeFunctionType.QrHub
    const editPanelButton = panelData?.Items?.[editPanelIndex]
    if (isHub && editPanelButton) {
      return { showMobileFunctionalityType: editPanelButton.FunctionalityType, showQrType: editPanelButton.QrType }
    }
    return { showMobileFunctionalityType: functionType, showQrType: Type }
  }, [functionType, panelData, editPanelIndex, Type])
  const isAngus = [QRCodeFunctionType.AngusServiceRequest, QRCodeFunctionType.BEServiceRequest].includes(
    showMobileFunctionalityType as QRCodeFunctionType
  )
  const defaultValue = {
    [QRCodeConfigEnum.QRType]: QRCodeType.Activate,
    [QRCodeConfigEnum.BGColor]: '#FFF',
    [QRCodeConfigEnum.FillColor]: '#000',
    [QRCodeConfigEnum.EyeColor]: '#000',
    [QRCodeConfigEnum.ECLevel]: '2',
    [QRCodeConfigEnum.EyeFrameShape]: '',
    [QRCodeConfigEnum.EyeBallShape]: '',
    [QRCodeConfigEnum.BodyShape]: '',
    [QRCodeConfigEnum.StripeText]: 'Activate',
    [QRCodeConfigEnum.Size]: '1000',
    [QRCodeConfigEnum.FileType]: 'eps',
    [QRCodeConfigEnum.BackgroundDimming]: 'rgba(255, 255, 255, .2)',
  }

  const qrCodeContextValue = useMemo<QRCodConfigContextValue>(
    () => ({
      getOptionValue: (name) => qrCodeOptions.current[name] || defaultValue[name],
      setOptionValue: (name, value) => {
        qrCodeOptions.current[name] = value
      },
    }),
    []
  )

  const childRef = useRef({
    onSaveQRRefresh: () => {},
  })

  // tab 点击事件
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabId(newValue)
  }

  const goBack = () => {
    navigate(-1)
  }

  const handleSuccess = (imageUrl: string, result: any) => {
    const qrIn = { ...qrInfo, ImageUrl: imageUrl, ...result }
    result?.PointingUrl && Object.assign(qrIn, { PointingUrl: result?.PointingUrl })
    setQrId(result?.acId)
    // @ts-ignore
    setQrInfo(qrIn)
  }

  const saveSuccess = useCallback((value = {}) => {
    setQrInfo((prevState) => {
      return Object.assign({}, prevState, { ...value })
    })
  }, [])

  const getFromProps = (functionalityType: string | undefined, showQrType: string | undefined) => {
    const isHub = functionType === QRCodeFunctionType.QrHub
    let newTemplate = template
    const editPanelButton = panelData?.Items?.[editPanelIndex]
    if (isHub && editPanelButton) {
      newTemplate = editPanelButton.TemplateFormData as unknown as Template
    }
    const qrTypeName = isHub ? editPanelButton?.Name : name
    const baseProps = {
      functionalityType: functionalityType,
      isHubPanel: true,
      qrType: showQrType,
      entity: qrInfo?.Entity,
      entityId: qrInfo?.Entity?.acId,
      language: 'en',
      logo: qrInfo?.HeaderLogo,
      selectedPropertyName: currentPropertyName,
      qrTypeName,
    }
    if (functionalityType === QRCodeFunctionType.QrHub) {
      Object.assign(baseProps, { data: newTemplate, bg: newTemplate?.HeaderImage })
    }
    switch (functionalityType) {
      case QRCodeFunctionType.Static:
      case QRCodeFunctionType.EmbeddedStaticUrl:
      case QRCodeFunctionType.PDF:
      case QRCodeFunctionType.Video: {
        // @ts-ignore
        const prop = PDF_MAP[functionType] || {}
        // @ts-ignore
        const url = newTemplate?.Url
        return {
          ...baseProps,
          url: url,
          title: newTemplate?.Title,
          ...prop,
          isPdf: QRCodeFunctionType.PDF === functionalityType,
        }
      }
      case QRCodeFunctionType.EmbeddedFile: {
        // @ts-ignore
        const url = newTemplate?.Url
        return { ...baseProps, url: url }
      }
      case QRCodeFunctionType.SimpleForm:
        return { ...baseProps, data: newTemplate?.Fields || newTemplate || [] }
      case QRCodeFunctionType.Html:
        return { ...baseProps, template: newTemplate }
      case QRCodeFunctionType.SocialMedia:
        return { ...baseProps, template: newTemplate }
      case QRCodeFunctionType.CustomerFeedback:
      case QRCodeFunctionType.Feedback:
        return { ...baseProps, data: newTemplate?.Questions }
      case QRCodeFunctionType.Purchase:
      case QRCodeFunctionType.Communication:
        return { ...baseProps, data: CommunicationMockData.Fields, title: CommunicationMockData.title }
      case QRCodeFunctionType.SupplierCheckin:
      case QRCodeFunctionType.StudentCheckin:
      case QRCodeFunctionType.GuestCheckin:
      case QRCodeFunctionType.TourCheckin:
      case QRCodeFunctionType.RoofAccess:
      case QRCodeFunctionType.RoofAccessCarousel:
      case QRCodeFunctionType.TenantHVAC:
        return {
          ...baseProps,
          data: newTemplate?.Fields || newTemplate || [],
          EnableSelfie: newTemplate?.EnableSelfie,
        }
      case QRCodeFunctionType.FieldEvent:
      case QRCodeFunctionType.BathroomService:
        return { ...baseProps, data: newTemplate?.Fields || [], title: newTemplate?.Title?.en }
      case QRCodeFunctionType.JoinWifi:
        return { ...baseProps, isFormData: true }
      case QRCodeFunctionType.Vacancy:
        return {
          ...baseProps,
          data: newTemplate?.Fields || newTemplate || [],
          title: newTemplate?.Title?.en,
          btnText: 'SEND REQUEST',
          btnWidth: '50%',
        }
      case QRCodeFunctionType.GoogleMap:
        return { ...baseProps, data: newTemplate }
      case QRCodeFunctionType.Renewal:
        return { ...baseProps, data: newTemplate }
      case QRCodeFunctionType.MoveOut:
      case QRCodeFunctionType.MoveIn:
      case QRCodeFunctionType.MoveOutResident:
        return { ...baseProps, data: newTemplate, type: functionalityType }
      case QRCodeFunctionType.FrontDesk:
      case QRCodeFunctionType.DownloadApp:
      case QRCodeFunctionType.AEDInspection:
      case QRCodeFunctionType.FireExtinguisher:
        return { ...baseProps, data: newTemplate }
      case QRCodeFunctionType.AngusServiceRequest:
      case QRCodeFunctionType.BEServiceRequest:
      case QRCodeFunctionType.Maintenance:
      case QRCodeFunctionType.MaintenanceRequest:
        return { ...baseProps, data: newTemplate?.Fields || [], title: newTemplate?.Title?.en }
      case QRCodeFunctionType.Support:
        return { ...baseProps, data: newTemplate?.Fields || [], title: newTemplate?.Title?.en }
      case QRCodeFunctionType.EVRequest:
      case QRCodeFunctionType.LeadCapture:
      case QRCodeFunctionType.RoomRental:
      case QRCodeFunctionType.FieldMarketing:
      case QRCodeFunctionType.CheckIn:
      case QRCodeFunctionType.EventRegistration:
        return { ...baseProps, template: newTemplate, title: newTemplate?.Title?.en, type: functionalityType }
      case QRCodeFunctionType.LadderSafety:
        return { ...baseProps, template: newTemplate }
      case QRCodeFunctionType.Inventory:
        return { ...baseProps, template: newTemplate }
      case QRCodeFunctionType.BurnPermit:
        return { ...baseProps, template: newTemplate }
      case QRCodeFunctionType.CommentCard:
        return {
          ...baseProps,
          data: newTemplate?.Fields || newTemplate || [],
          qrTypeName: 'Comment Card',
          btnText: 'Send Feedback',
          btnWidth: '50%',
        }
      case QRCodeFunctionType.ResidentCheckOut:
        return {
          ...baseProps,
          data: newTemplate?.Fields || newTemplate || [],
          btnText: 'CHECK-OUT',
          btnWidth: '50%',
        }
      default:
        return { ...baseProps }
    }
  }

  // @ts-ignore
  const FormCompName = DynamicComponentsMaps[showMobileFunctionalityType]
  const passProps = getFromProps(showMobileFunctionalityType, showQrType)
  const customEntityHandel = (event) => {
    const { name, value } = event.target
    if (!name) return
    const newValue = { ...entity, [name]: value } as EntityITF
    if (name === 'Type' && value === 'Building') {
      Object.assign(newValue, { Floor: '' })
      setNameEn('')
    }
    if (name === 'NameEn') {
      setNameEn(value)
    }
    !isEdit && saveEntity(newValue)
    // @ts-ignore
    setEntity(newValue)
    if (name === 'Type') {
      if (StopType === 'Location') {
        setState({ EquipmentType: value as string })
      }
    }
  }

  const fetchQrData = useCallback(async () => {
    if (!qrId) return
    const result: any = await getQrCodeDetail({ qrId: qrId })
    const { FunctionalityType, TemplateData, TemplateId } = result
    const isDraft = result.Status === 'Draft'
    if (!result.TemplateData) {
      result.TemplateData = { CanSave: false }
    }
    if (isDraft && FunctionalityType === QRCodeFunctionType.Feedback) {
      result.TemplateData.CanSave = true
    }
    if (FunctionalityType === QRCodeFunctionType.QrHub) {
      handlerHub(TemplateData)
      TemplateData.CanSave = true
    } else {
      result.TemplateData.CanSave = isCanSavaQr(FunctionalityType, TemplateData, TemplateId)
    }
    setTemplate(result.TemplateData)
    setQrInfo(result)
    setNameEn(result?.Entity?.Name?.en || '')
    const { Floor = '', Unit = '' } = result?.Entity || {}
    setEntity(Object.assign({}, result?.Entity, { floor: Floor, unit: Unit }))
    setState({
      name: result?.Name || '',
      EquipmentType: result?.EquipmentType,
      MechanicalSpace: result?.MechanicalSpace,
      StopType: result?.StopType,
      InspectionType: result?.InspectionType,
      Accessible: result?.Accessible,
      TenantId: result?.TenantId,
      TenantName: result?.TenantName,
      TagType: result?.TagType || 'QROnly',
      Skip1FA: result?.Skip1FA || false,
    })
  }, [])
  const onChange = useCallback((item: Template) => {
    setTemplate(item)
  }, [])

  const handleGeneratorCode = () => {
    setTabId(2)
    const { onSaveQRRefresh } = childRef.current
    onSaveQRRefresh()
  }

  const equipmentTypeOptions = useMemo(
    () => (StopType === 'Equipment' ? ROUND_EQUIPMENT_TYPE : types),
    [types, StopType]
  )
  const onChangeEquipmentType = (event: React.ChangeEvent<{}>, value: { Name: any } | null) => {
    setState({ EquipmentType: value?.Name || '' })
  }
  const onChangeDestination = (event: React.ChangeEvent<{}>, value: QRType | null) => {
    setQRType?.(value)
    setTemplate?.(null)
    const newState = { name: value?.DefaultQrName || value?.DisplayText?.en || '' }
    if (value?.FunctionalityType === QRCodeFunctionType.ActivitySchedule) {
      newState.name = 'Re-occurring Repair'
    }
    setState(newState)
    if (value && value.Location) {
      setEntity((pre) => {
        const nextData = { ...pre, Type: value?.Location }
        return nextData
      })
    }

    if (value?.FunctionalityType === QRCodeFunctionType.OpenAI) {
      // @ts-ignore
      setTemplate?.({
        TemplateId: '889b2720-a75d-11ed-b311-4f1f9659e967',
      })
    }
    if (
      value?.FunctionalityType &&
      [
        QRCodeFunctionType.CustomerFeedback,
        QRCodeFunctionType.Inventory,
        QRCodeFunctionType.ActivitySchedule,
        QRCodeFunctionType.Permit,
      ].includes(value.FunctionalityType)
    ) {
      setEntity({ acId: bId })
    }
  }

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setState({ [name]: value })
  }, [])

  const fetchTypes = useCallback(async () => {
    try {
      const data = await getEntityTypes({})
      // @ts-ignore
      setTypes(data.sort((a, b) => a.DisplayText?.['en'].localeCompare(b.DisplayText?.['en'])))
    } catch (e) {
      SnackBar({ msg: e, type: 'error', duration: 3000 })
    }
  }, [])
  const saveEntity = useDebouncedCallback(async (entityP: EntityITF) => {
    const curEntity = entityP || entity
    const entityType = curEntity?.Type
    const entityChecked = entityType && curEntity?.Floor
    const inspectionCanSave = isInspection && entityType && NameEn
    const angusCanSave = isAngus && entityType && NameEn
    const canSave =
      (!isBuilding && entityChecked && NameEn) || (isBuilding && entityType) || inspectionCanSave || angusCanSave
    if (!canSave) return
    if (isBuilding) {
      setEntity({ ...curEntity, acId: bId })
      return
    }
    const Type = curEntity.Type
    if (!Type) return
    try {
      const params = {
        GroupId: currentGroupId,
        BuildingId: bId,
        Type,
        Floor: curEntity.Floor,
        Unit: curEntity.Unit,
        NameEn,
        Location: Type,
        EntityId: curEntity && curEntity.acId === bId ? '' : curEntity?.acId || '',
      }
      const data = await SaveEntity(params)
      // @ts-ignore
      setEntity({ ...curEntity, ...data })
    } catch (e) {
      SnackBar({ msg: e, type: 'error', duration: 3000 })
    }
  }, 1000)
  useEffect(() => {
    if (qrIdP) return
    if (isRound) {
      const qrT = qrTypeList.find((item) => item.FunctionalityType === QRCodeFunctionType.EquipmentInspection)
      setQRType?.(qrT)
      if (qrT?.Location) setEntity((entity) => ({ Type: qrT.Location }))
    }
    if (isCustomerPortal) {
      const qrT = qrTypeList.find((item) => item.Name === qrName)
      setQRType?.(qrT)
      setState({ name: qrT?.DisplayText?.en || '' })
      saveEntity({ Type: 'Digital' } as EntityITF)
    }
    if (isInspection) {
      const qrT = qrTypeList.find((item) => item.FunctionalityType === QRCodeFunctionType.Inspection)
      setQRType?.(qrT)
      saveEntity({ Type: 'Digital' } as EntityITF)
    }
  }, [isRound, qrTypeList, isCustomerPortal, qrName, isInspection, qrIdP, setState, saveEntity])

  useEffect(() => {
    fetchQrData()
  }, [fetchQrData])

  useEffect(() => {
    fetchTypes()
  }, [])

  useEffect(() => {
    getTenantList({
      filter: JSON.stringify({ bId: currentPropertyId, gId: groupId }),
      pagination: JSON.stringify({ page: 0, perPage: 10000 }),
    }).then((res: any) => {
      setState({ TenantList: res.Tenants })
    })
  }, [isBathroomService, groupId, currentPropertyId, setState])

  useEffect(() => {
    if (!isEdit) return
    setQRType(qrTypeList.filter((v) => v.Name === Type)?.[0])
  }, [qrTypeList, Type, isEdit])
  const disabled = useMemo(() => {
    let canGenerator = true
    if (isRound && (!name || !EquipmentType || !StopType)) {
      canGenerator = false
    } else if (isInspection && (!name || !InspectionType || !NameEn)) {
      canGenerator = false
    } else if (
      qrType &&
      [QRCodeFunctionType.AngusServiceRequest, QRCodeFunctionType.BEServiceRequest].includes(qrType?.FunctionalityType)
    ) {
      if (!(name && entity && entity.Type && NameEn)) {
        canGenerator = false
      }
    } else if (
      (!hiddenEntity && !isInspection && !isBuilding && !(name && entity && entity.Floor && entity.Type && NameEn)) ||
      (isBuilding && !(name && entity && entity.Type))
    ) {
      canGenerator = false
    } else if (
      entity?.acId &&
      qrType?.FunctionalityType &&
      [
        QRCodeFunctionType.Announcements,
        QRCodeFunctionType.OpenAI,
        QRCodeFunctionType.NewSupplier,
        QRCodeFunctionType.RoundReport,
        QRCodeFunctionType.SupplierCheckReport,
        QRCodeFunctionType.FeedbackLocationReport,
        QRCodeFunctionType.TenantHealth,
        QRCodeFunctionType.TenantPerformance,
        QRCodeFunctionType.Permit,
        QRCodeFunctionType.EquipmentLookup,
        QRCodeFunctionType.CleaningRoundReport,
        QRCodeFunctionType.SupplierList,
        QRCodeFunctionType.BathroomServiceReport,
        QRCodeFunctionType.PortfolioInspectionDashboard,
        QRCodeFunctionType.BuildingInspectionDashboard,
        QRCodeFunctionType.SecurityPatrolDashboard,
      ].includes(qrType?.FunctionalityType)
    ) {
      canGenerator = true
    } else {
      canGenerator = (hiddenEntity || !!entity?.acId) && !!template?.CanSave
    }
    return !canGenerator
  }, [
    isRound,
    name,
    EquipmentType,
    StopType,
    isInspection,
    InspectionType,
    qrType,
    hiddenEntity,
    template?.CanSave,
    entity,
    NameEn,
    isBuilding,
  ])
  // let disabled =
  //   template?.CanSave === false ||
  //   !NameEn ||
  //   !name ||
  //   (isRound && (!MechanicalSpace || !EquipmentType || !StopType)) ||
  //   (isInspection && (!name || !InspectionType || !NameEn))
  //
  // if (isCustomerQr) {
  //   disabled = template?.CanSave === false || !name
  // }
  // if (isInspection) {
  //   disabled = template?.CanSave === false || !InspectionType || !NameEn
  // }
  // if (isMechanicalSpace) {
  //   disabled = true
  // }

  const isAngusNotIsHub = isAngus && !isHub
  const disabledEdit = (!GlobalManageQrCode && !isCustomerQr) || isMechanicalSpace
  if (qrIdP && !qrInfo) {
    return <Loading />
  }
  return (
    <QRCodeProvider value={qrCodeContextValue}>
      <FormDataContextProvider>
        <div className={classes.root}>
          <Box className={classes.backButton}>
            <BackButton handleBack={goBack} />
          </Box>
          <Box className={classes.container}>
            <Grid item xs={12} md={5} className={classes.left}>
              <MobileFrame logo={qrInfo?.HeaderLogo}>
                {FormCompName ? (
                  <FormCompName {...passProps} />
                ) : (
                  <Typography className={classes.noType}>Preview is not available for this type</Typography>
                )}
              </MobileFrame>
            </Grid>

            <Grid item xs={12} md={9} className={classes.right}>
              <Tabs value={tabId} onChange={handleTabChange} className={classes.tabSection}>
                {!isCustomerPortal && <Tab label="Design" value={0} className={classes.tabLabel} />}
                <Tab label="Content" value={1} className={classes.tabLabel} />
                <Tab label={isCustomerPortal ? 'Delivery' : 'Digital & Print'} value={2} className={classes.tabLabel} />
              </Tabs>

              <TabPanel value={tabId} index={0} className={classes.tabPanel}>
                <PickADesign
                  isEdit={isEdit}
                  gId={currentGroupId || ''}
                  bId={bId || ''}
                  qrInfo={qrInfo}
                  status={qrInfo?.QrStyle || ''}
                />
              </TabPanel>

              <TabPanel value={tabId} index={1} className={classes.tabPanel}>
                {/* @ts-ignore */}
                <Autocomplete
                  value={qrType || null}
                  disabled={isEdit}
                  onChange={onChangeDestination}
                  className={classes.autoComplete}
                  getOptionDisabled={() => false}
                  id="function-type"
                  options={qrTypeList}
                  getOptionLabel={(option) => option.DisplayText?.en || ''}
                  style={{ width: '100%', marginBottom: '12px', marginTop: '12px' }}
                  renderInput={(params) => <TextField {...params} label="QR Type" variant="outlined" />}
                  popupIcon={<ExpandMore />}
                />
                <CustomTextField
                  className={classes.formControl}
                  label={qrNameLabel}
                  disabled={disabledEdit}
                  name="name"
                  placeholder="Enter here"
                  onChange={handleChange}
                  value={name}
                  required
                  fullWidth
                  labelDisabled
                  variant="outlined"
                />
                <Autocomplete
                  value={TAG_TYPE.find((item) => item.value === TagType) || null}
                  onChange={(e, value) => setState({ TagType: value?.value || '' })}
                  options={TAG_TYPE}
                  getOptionLabel={(option) => option.label || ''}
                  className={classes.autoComplete}
                  style={{ width: '100%', marginBottom: '12px', marginTop: '12px' }}
                  renderInput={(params) => <TextField {...params} label="Tag Type" variant="outlined" required />}
                  popupIcon={<ExpandMore />}
                />
                {isRound && (
                  <Autocomplete
                    value={StopType || null}
                    disabled={isEdit}
                    className={classes.autoComplete}
                    id="stop-type"
                    options={['Equipment', 'Location']}
                    style={{ width: '100%', marginBottom: '12px', marginTop: '12px' }}
                    renderInput={(params) => <TextField {...params} label="Stop Type" variant="outlined" required />}
                    popupIcon={<ExpandMore />}
                  />
                )}
                {isRound && (
                  <Autocomplete
                    value={equipmentTypeOptions.find((item) => item.Name === EquipmentType) || null}
                    onChange={onChangeEquipmentType}
                    className={classes.autoComplete}
                    id="equipment-type"
                    disabled={isEdit}
                    options={equipmentTypeOptions}
                    getOptionLabel={(option) => option.DisplayText?.en || ''}
                    style={{ width: '100%', marginBottom: '12px', marginTop: '12px' }}
                    renderInput={(params) => (
                      <TextField {...params} label="Equipment Type" variant="outlined" required />
                    )}
                    popupIcon={<ExpandMore />}
                  />
                )}
                {isInspection && (
                  <Autocomplete
                    value={INSPECTION_TYPE.find((item) => item.Name === InspectionType) || null}
                    onChange={(e, value) => setState({ InspectionType: value?.Name || '' })}
                    options={INSPECTION_TYPE}
                    getOptionLabel={(option) => option.DisplayText?.en || ''}
                    className={classes.autoComplete}
                    style={{ width: '100%', marginBottom: '12px' }}
                    renderInput={(params) => (
                      <TextField {...params} label="Inspection Type" variant="outlined" required />
                    )}
                    popupIcon={<ExpandMore />}
                  />
                )}
                {!hiddenEntity && qrType && (
                  <Accordion className={classes.accordion} defaultExpanded={!qrIdP}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      classes={{
                        root: classes.accordionSummary,
                        expanded: classes.accordionSummaryExpanded,
                      }}
                    >
                      <Typography>QR Sign Location</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      {![isBuilding, isAngusNotIsHub, isInspection].includes(true) && (
                        <CustomTextField
                          label="Floor"
                          labelDisabled
                          disabled={disabledEdit}
                          name="Floor"
                          placeholder="Enter here"
                          onChange={customEntityHandel}
                          value={entity.Floor}
                          className={classes.formControl}
                          variant="outlined"
                        />
                      )}
                      <FormControl className={classes.formControl} style={{ marginBottom: '12px', marginTop: '12px' }}>
                        <InputLabel id="entity-label" className={classes.menuLabel}>
                          Location
                        </InputLabel>
                        <Select
                          value={entity?.Type || ''}
                          labelId="Entity Type"
                          disabled={isEdit}
                          id="Type"
                          name="Type"
                          onChange={customEntityHandel}
                          IconComponent={ExpandMore}
                        >
                          {types.map((item) => {
                            // @ts-ignore
                            return (
                              <MenuItem value={item.Name} key={item.Name}>
                                {item.DisplayText?.['en']}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                      {isRound && (
                        <CustomTextField
                          label="Mechanical Space"
                          disabled={disabledEdit}
                          required
                          name="MechanicalSpace"
                          onChange={handleChange}
                          placeholder="Enter here"
                          value={MechanicalSpace}
                          className={classes.formControl}
                          variant="outlined"
                        />
                      )}
                      {![isRound, isAngusNotIsHub, isInspection, isMechanicalSpace].includes(true) && (
                        <CustomTextField
                          label="Unit"
                          disabled={disabledEdit}
                          labelDisabled
                          name="Unit"
                          placeholder="Enter here"
                          onChange={customEntityHandel}
                          value={entity.Unit}
                          className={classes.formControl}
                          variant="outlined"
                        />
                      )}
                      {isBathroomService && (
                        <>
                          <Autocomplete
                            value={Accessible || null}
                            onChange={(e, value) => {
                              setState({ Accessible: value, TenantId: '', TenantName: '' })
                            }}
                            id="Accessible"
                            options={['Public', 'Private']}
                            className={classes.autoComplete}
                            style={{ width: '100%', marginBottom: '12px', marginTop: '12px' }}
                            renderInput={(params) => (
                              <TextField {...params} label=" Private or Public" variant="outlined" />
                            )}
                            popupIcon={<ExpandMore />}
                          />
                          {Accessible === 'Private' && (
                            <Autocomplete
                              value={TenantList.find((item) => item.acId === TenantId) || null}
                              onChange={(e, value) => setState({ TenantId: value?.acId, TenantName: value?.Name })}
                              options={TenantList}
                              getOptionLabel={(option) => option.Name || ''}
                              className={classes.autoComplete}
                              style={{ width: '100%', marginBottom: '12px', marginTop: '12px' }}
                              renderInput={(params) => <TextField {...params} label="Tenant Name" variant="outlined" />}
                              popupIcon={<ExpandMore />}
                            />
                          )}
                        </>
                      )}
                      {!isBuilding && (
                        <TextField
                          value={NameEn}
                          required
                          disabled={disabledEdit}
                          className={classes.formControl}
                          placeholder="Enter here"
                          id="outlined-multiline-static"
                          label="Installation Note"
                          name="NameEn"
                          variant="outlined"
                          onChange={customEntityHandel}
                          InputLabelProps={{
                            disabled: true,
                          }}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
                {((isEdit && template && !!entity?.acId) || (!qrId && !!entity?.acId)) && (
                  <EditAllPanel
                    getTemplate={!isEdit}
                    isEdit={isEdit}
                    disabledEdit={disabledEdit}
                    isHub={isHub}
                    name={name}
                    groupId={currentGroupId || ''}
                    qrId={qrId || ''}
                    onChange={onChange}
                    entity={entity}
                    qrType={qrType}
                    nameEn={NameEn}
                    defaultTemplate={template}
                    saveSuccess={saveSuccess}
                    templateId={templateId}
                    bId={bId as string}
                    qrStyle={qrInfo?.QrStyle}
                    qrStatus={qrInfo?.Status}
                    Accessible={Accessible}
                    TenantId={TenantId}
                    TenantName={TenantName}
                    TagType={TagType}
                    Skip1FA={Skip1FA}
                    setState={setState}
                  />
                )}
              </TabPanel>

              <TabPanel value={tabId} index={2} className={classes.tabPanel}>
                <MemoizedQRConfig
                  childRef={childRef}
                  groupId={currentGroupId || ''}
                  buildingId={qrInfo?.BuildingId || ''}
                  qrType={qrType}
                  templateId={templateId}
                  entityId={entity?.acId}
                  customEntity={entity}
                  qrId={qrId}
                  nameEn={NameEn}
                  editTemplate={template}
                  canGenerator={false}
                  qrName={name || ''}
                  handleSuccess={handleSuccess}
                  url={qrInfo?.ImageUrl}
                  pointingUrl={qrInfo?.PointingUrl}
                  isEdit={isEdit}
                  disabledEdit={disabledEdit}
                  mechanicalSpace={MechanicalSpace}
                  equipmentType={EquipmentType}
                  stopType={StopType}
                  inspectionType={InspectionType}
                  Accessible={Accessible}
                  TenantId={TenantId}
                  TenantName={TenantName}
                  TagType={TagType}
                  Skip1FA={Skip1FA}
                />
                {!!qrInfo?.acId && (
                  <PrintKit qrInfo={qrInfo} disabledEdit={disabledEdit || qrInfo?.Status === QRStatusType.Draft} />
                )}
              </TabPanel>

              {[0, 1].includes(tabId) && (GlobalManageQrCode || !disabledEdit) && (
                <Box className={classes.buttonBox}>
                  <Button onClick={goBack} variant="outlined" style={{ marginRight: '15px' }}>
                    Cancel
                  </Button>
                  <Button disabled={disabled} onClick={handleGeneratorCode} color="primary" variant="contained">
                    Generate QR Code
                  </Button>
                </Box>
              )}
            </Grid>
          </Box>
        </div>
      </FormDataContextProvider>
    </QRCodeProvider>
  )
}

const QRCodeEditWrapper: React.FC = () => {
  return (
    <EditPanelContextProvider>
      <QRCodeEdit />
    </EditPanelContextProvider>
  )
}

export default QRCodeEditWrapper
