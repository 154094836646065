import React, { useEffect, useState, useMemo } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Typography, Autocomplete, TextField, Button } from '@mui/material'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import useSetState from '@hooks/useSetState'
import { Mail, ExpandMore } from '@mui/icons-material'
import { submitDashboardReportRequest, submitEmailReportRequest, getPropertyDetail } from '@api'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { startOfDay, endOfDay } from 'date-fns'
import SnackBar from '@component/SnackBar'
import SendEmail from '@/components/Modal/SendEmail'
import { Loading } from 'ra-ui-materialui'
import { selectedProperty } from '@reducer/profile'
import { IEntity } from '@/types'

export const getTableList = () =>
  [
    {
      label: 'Engineering Rounds',
      value: 'Engineering',
      path: '/analytics/dashboard/roundAnalytics/Engineering',
      emailDisabled: false,
      puppeteerUrl: 'pdf/Engineering',
      reportBuilder: true,
    },
    {
      label: 'AED Rounds',
      value: 'AEDInspection',
      path: '/analytics/dashboard/roundAnalytics/AEDInspection',
      emailDisabled: false,
    },
    {
      label: 'Fire Extinguisher Rounds',
      value: 'FireExtinguisherInspection',
      path: '/analytics/dashboard/roundAnalytics/FireExtinguisherInspection',
      emailDisabled: false,
    },
    {
      label: 'Inspection Rounds',
      value: 'InspectionRound',
      path: '/analytics/dashboard/roundAnalytics/Inspection',
      emailDisabled: false,
      reportBuilder: true,
    },
    {
      label: 'Cleaning Rounds',
      value: 'Cleaning',
      path: '/analytics/dashboard/roundAnalytics/Cleaning',
      emailDisabled: false,
      reportBuilder: true,
    },
    {
      label: 'Security Patrols',
      value: 'SecurityPatrol',
      path: '/analytics/dashboard/roundAnalytics/SecurityPatrol',
      emailDisabled: false,
      reportBuilder: true,
    },
    {
      label: 'Preventative Maintenance',
      value: 'PreventativeMaintenance',
      path: '/analytics/dashboard/preventativeMaintenance',
      emailDisabled: false,
      showEmail: false,
    },
    {
      label: 'PM Library Dashboard',
      value: 'PMLibraryDashboard',
      path: '/analytics/dashboard/pMLibraryDashboard',
      emailDisabled: false,
      showEmail: false,
    },
    {
      label: 'Inspection Dashboard',
      value: 'Inspection',
      path: '/analytics/dashboard/inspectionReport',
      emailDisabled: false,
    },
    {
      label: 'Supplier SLA Report',
      value: 'SupplierSLAReport',
      path: '/analytics/dashboard/supplierSLAReport',
      emailDisabled: false,
    },
    {
      label: 'Feedback Dashboard',
      value: 'FeedbackDashboard',
      path: '/analytics/dashboard/reportAnalyticsFeedback/Location',
      emailDisabled: false,
      emailSendUrl: true,
    },
    {
      label: 'Bathroom Service',
      value: 'BathroomService',
      path: '/analytics/dashboard/bathroomService',
      emailDisabled: true,
      reportBuilder: true,
    },
    {
      label: 'Tenant Performance Dashboard',
      value: 'TenantPerformanceDashboard',
      path: '/analytics/dashboard/tenantExperience/1',
      emailDisabled: false,
      emailSendUrl: true,
      reportBuilder: true,
    },
    {
      label: 'Tenant Health Dashboard',
      value: 'TenantHealthDashboard',
      path: '/analytics/dashboard/tenantExperience/2',
      emailDisabled: false,
      emailSendUrl: true,
      reportBuilder: true,
    },
    {
      label: 'Supplier Check In Report',
      value: 'SupplierCheckInReport',
      path: '/analytics/dashboard/supplierCheckInReport',
      emailDisabled: false,
      reportBuilder: true,
    },
    {
      label: 'Roof Access Authorization Report',
      value: 'RoofAccessReport',
      path: '/analytics/dashboard/roofAccessAuthorizationReport',
      emailDisabled: false,
      reportBuilder: true,
    },
    {
      label: 'Tenant HVAC Authorization Report',
      value: 'TenantHVACReport',
      path: '/analytics/dashboard/tenantHVACReport',
      emailDisabled: false,
    },
    {
      label: 'Feedback Dashboard',
      value: 'GlobalFeedbackDashboard',
      path: '/portfolioAnalytics/globalDashboard/globalReport',
      emailDisabled: false,
      emailSendUrl: true,
    },
    {
      label: 'Tenant Performance Dashboard',
      value: 'GlobalTenantPerformanceDashboard',
      path: '/portfolioAnalytics/globalDashboard/globalTenantExperience/1',
      emailDisabled: false,
      emailSendUrl: true,
    },
    {
      label: 'Tenant Health Dashboard',
      value: 'GlobalTenantHealthDashboard',
      path: '/portfolioAnalytics/globalDashboard/globalTenantExperience/2',
      emailDisabled: false,
      emailSendUrl: true,
    },
    {
      label: 'Inspection Dashboard',
      value: 'GlobalInspectionReport',
      path: '/portfolioAnalytics/globalDashboard/globalInspectionReport',
      emailDisabled: true,
    },
    {
      label: 'Portfolio Security',
      value: 'GlobalPortfolioSecurity',
      path: '/portfolioAnalytics/globalDashboard/globalSecurityPatrol',
      showEmail: false,
    },
    {
      label: 'Insurance Dashboard',
      value: 'GlobalInsuranceDashboard',
      path: '/portfolioAnalytics/globalDashboard/globalInsuranceDashboard',
      emailDisabled: true,
    },
    {
      label: 'Social Reputation',
      value: 'GlobalSocialReputation',
      path: '/portfolioAnalytics/globalDashboard/globalGoogleRatingReport',
      showEmail: false,
    },
    {
      label: 'Upsell Matrix',
      value: 'GlobalUpsellMatrix',
      path: '/portfolioAnalytics/globalDashboard/globalUpsellMatrix',
      showEmail: false,
    },
    {
      label: 'Vacancy Lead',
      value: 'Vacancy',
      path: '/analytics/dashboard/vacancyLead',
      emailDisabled: false,
    },
    {
      label: 'Lead Capture',
      value: 'LeadCapture',
      path: '/analytics/dashboard/leadCapture/LeadCapture',
      emailDisabled: false,
    },
    {
      label: 'EV Request Report',
      value: 'EVRequest',
      path: '/analytics/dashboard/leadCapture/EVRequest',
      emailDisabled: false,
    },
    {
      label: 'Room Rental',
      value: 'RoomRental',
      path: '/analytics/dashboard/leadCapture/RoomRental',
      emailDisabled: false,
    },
    {
      label: 'Field Marketing',
      value: 'FieldMarketing',
      path: '/analytics/dashboard/leadCapture/FieldMarketing',
      emailDisabled: false,
    },
    {
      label: 'Registrant List',
      value: 'EventRegistration',
      path: '/analytics/dashboard/leadCapture/EventRegistration',
      emailDisabled: false,
    },
    {
      label: 'Renewal Report',
      value: 'Renewal',
      path: '/analytics/dashboard/renewalReport',
      emailDisabled: false,
    },
    {
      label: 'In App Feedback',
      value: 'InAppFeedbackReport',
      path: '/analytics/dashboard/inAppFeedbackReport',
      emailDisabled: false,
    },
    {
      label: 'Move out Inspection',
      value: 'MoveOutReport',
      path: '/analytics/dashboard/moveOutReport',
      emailDisabled: false,
      showEmail: false,
    },
    {
      label: 'Equipment Lookup',
      value: 'EquipmentLookup',
      path: '/analytics/dashboard/equipmentLookup',
      emailDisabled: false,
      showEmail: false,
    },
    {
      label: 'Master Notepad',
      value: 'MasterNotepad',
      puppeteerUrl: 'pdfProofReport',
      path: '/analytics/dashboard/masterNotepad',
    },
    {
      label: 'Staff Check In Report',
      value: 'StaffCheckIn',
      path: '/analytics/dashboard/staffCheckIn',
    },
  ].sort((a, b) => a.label.localeCompare(b.label))

const Dashboard: React.FC = () => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const {
    selectedGroupId,
    selectedPropertyId,
    selectedPropertyName,
    propertyTag: { PropertyTagId = '' } = {},
  } = useSelector((state) => state.profile)
  const {
    bId = selectedPropertyId,
    gId = selectedGroupId,
    ptId = PropertyTagId,
  } = useParams<{ bId: string; gId: string; ptId: string }>()
  const navigate = useNavigate()
  const { user, hasPermissions } = useVerifyDashboard(gId, bId)
  const [{ value, path }, setState] = useSetState({
    value: '',
    path: '',
    label: '',
  })
  const [open, setOpen] = useState(false)

  const tableList = useMemo(() => {
    const TABLE_LIST = getTableList()
    if (pathname.includes('/globalDashboard')) {
      return TABLE_LIST.filter((v) => v.value.includes('Global'))
    } else {
      return TABLE_LIST.filter((v) => !v.value.includes('Global'))
    }
  }, [bId])
  const selectedItem = tableList.find((v) => v.value === value || pathname.includes(v.path))
  const emailSendUrl = selectedItem?.emailSendUrl || false
  const emailDisabled = selectedItem?.emailDisabled || !selectedItem?.value
  const showEmail = selectedItem?.showEmail !== false

  const onSend = (ContactEmails: string[], Emails: string[]) => {
    const { StartDate = '', EndDate = '', tu, ...other } = JSON.parse(localStorage.getItem('DashboardDate') || '{}')
    const api = emailSendUrl ? submitEmailReportRequest : submitDashboardReportRequest
    const path = (selectedItem?.path || '').substring(1)
    const pdfTitle = `${selectedItem?.label}: ${selectedPropertyName}`
    let transPdfUrl = selectedItem?.puppeteerUrl
      ? `${selectedItem?.puppeteerUrl}/${gId}/${ptId}/${bId}/${tu}/${pdfTitle}`
      : undefined
    console.log('selectedItem', selectedItem)
    if (selectedItem?.value === 'MasterNotepad') {
      transPdfUrl = `${selectedItem?.puppeteerUrl}/${gId}/${bId}/${other.ContentType || 'ContentProof'}`
    }
    api({
      GroupId: gId,
      BuildingId: bId,
      DashboardName: selectedItem?.label,
      ReportType: selectedItem?.value,
      StartDate: StartDate && startOfDay(new Date(StartDate)).getTime(),
      EndDate: EndDate && endOfDay(new Date(EndDate)).getTime(),
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...other,
      ContactEmails,
      Emails,
      Path: `${path}/${bId}`,
      TransPdfUrl: transPdfUrl,
    }).then(() => {
      SnackBar({ msg: 'The dashboard has been sent!', type: 'success' })
      setOpen(false)
    })
  }
  console.log('selectedItem', selectedItem)

  useEffect(() => {
    if (path) {
      navigate(path, { replace: true })
    }
  }, [path, navigate])

  if (!user) {
    return <Loading />
  }

  if (!hasPermissions) {
    return (
      <Typography textAlign="center" sx={{ fontSize: '32px', marginTop: '24px' }}>
        You don&apos;t have permission to this page
      </Typography>
    )
  }

  return (
    <Box className={classes.paper}>
      <Box className={classes.header}>
        <Autocomplete
          disableClearable
          onChange={(_, newValue) => setState(newValue)}
          options={tableList}
          sx={{ width: '500px', marginRight: '24px' }}
          value={selectedItem}
          getOptionLabel={(option: any) => option.label}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Dashboard" />}
          popupIcon={<ExpandMore />}
        />
        {showEmail && (
          <Button variant="outlined" startIcon={<Mail />} disabled={emailDisabled} onClick={() => setOpen(true)}>
            EMAIL
          </Button>
        )}
      </Box>
      {pathname === '/analytics/dashboard' && (
        <Box className={classes.area}>
          <Typography variant="h5" sx={{ fontSize: '32px', color: '#1976D2' }}>
            Area for selected Dashboard
          </Typography>
        </Box>
      )}

      <Outlet />

      {open && <SendEmail onClose={() => setOpen(false)} onSend={onSend} onlyContact={emailSendUrl} />}
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: '#F5F5F5',
    flex: 1,
    padding: '0 24px 18px',
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    backgroundColor: '#F5F5F5',
    marginBottom: 24,
  },
  area: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
  },
}))

const useVerifyDashboard = (gId: string, bId: string) => {
  const { user, selectedGroupId, selectedPropertyId } = useSelector((state) => state.profile)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const shouldSelectedProperty = user && bId && selectedGroupId === gId && selectedPropertyId !== bId //已登录 且 选择的bId不等于url中的bId

  let hasPermissions = user?.UserContext?.PermissionsHash.ViewReports as unknown as boolean
  if (pathname.includes('/globalDashboard')) {
    hasPermissions = user?.UserContext?.PermissionsHash.ViewAnalytics as unknown as boolean
  }

  useEffect(() => {
    if (!user) {
      navigate('/login', {
        replace: true,
        state: {
          nextPathname: pathname,
          redirectToNextPath: true,
        },
      })
    }
  }, [navigate, user, pathname])

  useEffect(() => {
    if (shouldSelectedProperty) {
      getPropertyDetail<IEntity>({ eId: bId }).then((res) => {
        if (!res) return
        const name = res?.Name?.en || ''
        const buildingCode = res?.Code
        const propertyId = res?.BuildingId
        const propertyTag = res?.PropertyTag || {}
        dispatch(selectedProperty({ propertyId, propertyName: name, buildingCode, propertyTag }))
      })
    }
  }, [shouldSelectedProperty, bId, dispatch])
  return { hasPermissions, user: !!user }
}

export default Dashboard
