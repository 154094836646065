import * as React from 'react'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Divider, IconButton, Tab, Tabs } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import {
  BooleanField,
  Datagrid,
  DatagridProps,
  DateField,
  EmailField,
  ExportButton,
  List,
  ListContextProvider,
  ListProps,
  Resource,
  TextField,
  useListContext,
} from 'react-admin'
import { makeStyles } from '@mui/styles'
import { DEFAULT_DATE_RANGE_TODAY, DEFAULT_PERIOD_LAST_YEAR } from '@/global'
import PhoneNumberFiled from '@component/List/PhoneNumberFiled'
import ImageField from '@component/List/ImageField'
import QRSupportReport from './QRSupportReport'
import { GroupOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { ReportTypeEnum } from '@constant/report'
import CustomDateRange from '@component/DateSelect/CustomDateRange'
import { DateRange } from '@mui/x-date-pickers-pro'
import { Dayjs } from 'dayjs'

const useDatagridStyles = makeStyles({
  backIcon: {
    borderRadius: '0px',
    color: '#374053',
    flexGrow: 0,
    fontFamily: 'Roboto, Regular',
    fontSize: '20px',
    textTransform: 'none',
    border: 'none',
    padding: '0px',
    height: 35,
    marginRight: 16,
    cursor: 'pointer',
    '&.Mui-disabled': {
      color: 'transparent',
      opacity: 0.5,
    },
  },
  total: {
    fontWeight: 'bold',
  },
  tab: {
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 700,
    height: 70,
  },
  image: {
    '&>img': {
      width: 60,
      height: 60,
      marign: 0,
    },
  },

  filterDate: {
    display: 'flex',
    marginLeft: '10px',
  },
  content: {
    margin: '0px 10px 0',
  },
  head: {
    color: '#4E4D4D',
    fontSize: 20,
    padding: '7px 0 15px',
    fontFamily: 'Roboto, Regular',
  },
  period: {
    height: 48,
    width: 200,
    color: '#4D4D4D',
    fontSize: 16,
    borderRadius: 6,
    backgroundColor: 'white',
  },
  notchedOutline: {
    borderColor: '#A29D9D',
  },
  action: {
    marginTop: 20,
  },
})

const needDateTabType = ['fieldmarketing', 'leadCapture', 'eVRequest']

const withDictionary_Type = {
  'fieldmarketing-2': ['ColumnNames'],
  'leadCapture-2': ['ColumnNames'],
  'eVRequest-2': ['ColumnNames'],
}

const dealWithDate = (name) => {
  return needDateTabType.some((i) => i === name)
}

const tabs = [
  { id: 'vacancy', name: 'vacancy', label: 'Vacancy-Lead', type: ReportTypeEnum.Vacancy },
  // { id: 'fieldevent', name: 'fieldevent', label: 'Event Registrants' },
  // { id: 'communication', name: 'communication', label: '2 Way Communications' },
  { id: 'fieldmarketing', name: 'fieldmarketing', label: 'Field Marketing', type: ReportTypeEnum.FieldMarketing },
  { id: 'leadCapture', name: 'leadCapture', label: 'Lead Capture', type: ReportTypeEnum.LeadCapture },
  { id: 'eVRequest', name: 'eVRequest', label: 'EV Request Report', type: ReportTypeEnum.EVRequest },
  { id: 'maintenance', name: 'maintenance', label: 'Maintenance', type: ReportTypeEnum.Maintenance },
  { id: 'qrSupportReport', name: 'qrSupportReport', label: 'QR Support Report', type: ReportTypeEnum.Support },
  { id: 'roomRental', name: 'roomRental', label: 'Room Rental', type: ReportTypeEnum.RoomRental },
]
const dateFormat = {
  locales: 'en-US',
  options: { year: 'numeric', month: '2-digit', day: '2-digit' } as Intl.DateTimeFormatOptions,
}

interface TabbedDatagridProps extends DatagridProps {
  onTabChange: (tab: string) => void
  bId?: string
}
const TabbedDatagrid = (props: TabbedDatagridProps) => {
  const listContext = useListContext()
  const { filterValues, setFilters, displayedFilters } = listContext
  const classes = useDatagridStyles()
  const { selectedGroupId, selectedPropertyId, user } = useSelector((state) => state.profile)
  const [range, setRange] = useState<any>(DEFAULT_DATE_RANGE_TODAY)
  const [selectItem = {}, setSelectItem] = React.useState({})
  const [oldFilter, setOldFilter] = React.useState({})
  const getSubList = Object.keys(selectItem).length !== 0
  const currentGroupId = selectedGroupId || user?.UserContext?.CurrentGroupId
  const { onTabChange, bId, data = {} } = props
  let withDictionary_obj: { ColumnNames: any[]; ColumnFieldNameMap: { [x: string]: string | undefined } } = {
    ColumnNames: [],
    ColumnFieldNameMap: {},
  }
  // @ts-ignore
  if (withDictionary_Type[filterValues.tableName + '-' + (getSubList ? 2 : 1)] && Object.keys(data).length > 0) {
    const dataValue = Object.values(data || {})?.[0] || {}
    // @ts-ignore
    withDictionary_obj = dataValue
  }
  useEffect(() => {
    if (currentGroupId !== filterValues.gId || (filterValues.bId && selectedPropertyId !== filterValues.bId)) {
      //防止头部 切换group时 gid不变
      setFilters && setFilters({ ...filterValues, gId: currentGroupId, bId }, displayedFilters)
    }
    if (filterValues.getSubList) {
      //防止二级页面数据丢失
      setOldFilter(filterValues)
      setSelectItem({ acId: filterValues.eventId })
    }
  }, [])

  useEffect(() => {
    onTabChange?.(filterValues.tableName)
  }, [filterValues])
  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      const initFilterVal = { ...(!getSubList ? filterValues : oldFilter), tableName: value, getSubList: false }
      let lastFilterVal
      if (dealWithDate(value)) {
        lastFilterVal = { ...initFilterVal, tu: DEFAULT_PERIOD_LAST_YEAR.value }
      } else {
        initFilterVal.tu = ''
        lastFilterVal = initFilterVal
      }
      setRange(DEFAULT_DATE_RANGE_TODAY)
      setSelectItem({})
      setFilters?.(lastFilterVal, displayedFilters)
    },
    [displayedFilters, filterValues, setFilters, getSubList, onTabChange]
  )

  const handleDateChange = useCallback(
    (value: DateRange<Dayjs>) => {
      setRange(value)
      if (range?.length === 2 && range[0]?.isValid() && range[1]?.isValid()) {
        Object.assign(filterValues, { startDate: range[0].valueOf(), endDate: range[1].valueOf() })
      }
      setFilters && setFilters(filterValues, displayedFilters)
    },
    [displayedFilters, filterValues, setFilters]
  )

  const fieldmarketingClick = useCallback(
    (id: any, path: string, record: any) => {
      setOldFilter(filterValues)
      setSelectItem(record)
      const filterVal = { ...filterValues, eventId: record.acId, getSubList: true }
      if (filterValues.tableName === 'roomRental') {
        Object.assign(filterVal, { tu: '' })
      }
      setFilters?.(filterVal, displayedFilters)
    },
    [setFilters, filterValues, displayedFilters]
  )

  const onBack = useCallback(() => {
    setSelectItem({})
    const filterVal = { ...oldFilter, getSubList: false }
    if (range?.length === 2 && range[0]?.isValid() && range[1]?.isValid()) {
      Object.assign(filterVal, { startDate: range[0].valueOf(), endDate: range[1].valueOf() })
    }
    setFilters && setFilters(filterVal, displayedFilters)
  }, [oldFilter, displayedFilters, range])

  return (
    <Fragment>
      <Tabs
        value={filterValues.tableName}
        indicatorColor="primary"
        onChange={handleChange}
        textColor="primary"
        scrollButtons="auto"
        variant="scrollable"
      >
        {tabs.map((choice) => (
          <Tab key={choice.id} label={choice.label} value={choice.id} className={classes.tab} />
        ))}
      </Tabs>
      <Divider />
      <div>
        {dealWithDate(filterValues.tableName) && (
          <Box
            className={classes.filterDate}
            style={
              !getSubList
                ? { justifyContent: 'flex-end', padding: '15px 10px 0' }
                : { justifyContent: 'flex-start', padding: '14.5px 10px' }
            }
          >
            {!getSubList ? (
              <CustomDateRange value={range} onChange={handleDateChange} label="Date Range" />
            ) : (
              <Box>
                <IconButton edge="start" color="inherit" className={classes.backIcon} onClick={onBack}>
                  <ArrowBackIcon style={{ marginRight: 8 }} /> BACK
                </IconButton>
              </Box>
            )}
          </Box>
        )}
        {/* {filterValues.tableName === 'communication' && (
          <ListContextProvider
            value={{ ...listContext, ids: communication }}
          >
            <Datagrid {...props} optimized rowClick='edit' >
              <TextField source='id' />
            </Datagrid>
          </ListContextProvider>
        )} */}
        {filterValues.tableName === 'leadCapture' ? (
          !getSubList ? (
            <Box className={classes.content}>
              <Box className={classes.head}>Lead Capture</Box>
              <ListContextProvider value={{ ...listContext }}>
                {/* @ts-ignore */}
                <Datagrid {...props} bulkActionButtons={false} optimized rowClick={fieldmarketingClick}>
                  <TextField source="EventName" label="Lead Capture Name" />
                  <PhoneNumberFiled source="Floor" label="Floor" />
                  <TextField source="ParticipantNumber" label="Leads" />
                </Datagrid>
              </ListContextProvider>
            </Box>
          ) : (
            <Box className={classes.content}>
              <Box className={classes.head}>
                {/* @ts-ignore */}
                {selectItem?.Name || ''}
              </Box>
              <ListContextProvider value={{ ...listContext }}>
                <Datagrid {...props} bulkActionButtons={false} optimized>
                  <TextField source="Name" label="Name" />
                  <PhoneNumberFiled source="PhoneNumber" label="Phone No." />
                  <EmailField source="Email" />
                  {withDictionary_obj?.ColumnNames?.map((i: any) => {
                    if (i.FieldStyle === 'UploadImage') {
                      return <ImageField source={i.Key} label={i.Label} key={i.Key} />
                    }
                    return <TextField source={i.Key} label={i.Label} key={i.Key} sortable={false} />
                  })}
                </Datagrid>
              </ListContextProvider>
            </Box>
          )
        ) : null}
        {filterValues.tableName === 'fieldmarketing' ? (
          !getSubList ? (
            <Box className={classes.content}>
              <Box className={classes.head}>Field Marketing</Box>
              <ListContextProvider value={{ ...listContext }}>
                {/* @ts-ignore */}
                <Datagrid {...props} bulkActionButtons={false} optimized rowClick={fieldmarketingClick}>
                  <TextField source="EventName" label="Field Marketing Name" />
                  <PhoneNumberFiled source="Floor" label="Floor" />
                  <TextField source="EventLocation" label="Location" />
                  <TextField source="ParticipantNumber" label="Leads" />
                </Datagrid>
              </ListContextProvider>
            </Box>
          ) : (
            <Box className={classes.content}>
              <Box className={classes.head}>
                {/* @ts-ignore */}
                {selectItem?.Name || ''}
              </Box>
              <ListContextProvider value={{ ...listContext }}>
                <Datagrid {...props} bulkActionButtons={false} optimized>
                  <TextField source="Name" label="Name" />
                  <PhoneNumberFiled source="PhoneNumber" label="Phone No." />
                  <EmailField source="Email" />
                  {withDictionary_obj?.ColumnNames?.map((i: any) => {
                    if (i.FieldStyle === 'UploadImage') {
                      return <ImageField source={i.Key} label={i.Label} key={i.Key} />
                    }
                    return <TextField source={i.Key} label={i.Label} key={i.Key} sortable={false} />
                  })}
                </Datagrid>
              </ListContextProvider>
            </Box>
          )
        ) : null}
        {filterValues.tableName === 'eVRequest' ? (
          !getSubList ? (
            <Box className={classes.content}>
              <Box className={classes.head}>EV Request Report</Box>
              <ListContextProvider value={{ ...listContext }}>
                {/* @ts-ignore */}
                <Datagrid {...props} bulkActionButtons={false} optimized rowClick={fieldmarketingClick}>
                  <TextField source="EventLocation" label="EV Location" />
                  <PhoneNumberFiled source="Floor" label="Floor" />
                  <TextField source="EntityName.en" label="Installation Note" />
                  <TextField source="ParticipantNumber" label="No. of Requests" />
                </Datagrid>
              </ListContextProvider>
            </Box>
          ) : (
            <Box className={classes.content}>
              <Box className={classes.head}>
                {/* @ts-ignore */}
                {selectItem?.Name || ''}
              </Box>
              <ListContextProvider value={{ ...listContext }}>
                <Datagrid {...props} bulkActionButtons={false} optimized>
                  <DateField source="SubmitDate" label="Date" {...dateFormat} />
                  <TextField source="Name" label="Name" />
                  <EmailField source="Email" label="Email Address" />
                  <PhoneNumberFiled source="PhoneNumber" label="Mobile No." />
                  <PhoneNumberFiled source="Unit" label="Unit" />
                  {withDictionary_obj?.ColumnNames?.map((i: any) => {
                    if (i.FieldStyle === 'UploadImage') {
                      return <ImageField source={i.Key} label={i.Label} key={i.Key} />
                    }
                    return <TextField source={i.Key} label={i.Label} key={i.Key} sortable={false} />
                  })}
                </Datagrid>
              </ListContextProvider>
            </Box>
          )
        ) : null}
        {filterValues.tableName === 'vacancy' && (
          <Box className={classes.content}>
            <Box className={classes.head}>Vacancy-Lead</Box>
            <ListContextProvider value={{ ...listContext }}>
              <Datagrid {...props} bulkActionButtons={false} optimized rowClick="edit">
                <TextField source="QrName" label="QR Name" />
                <DateField source="CreatedDate" label="Date" {...dateFormat} />
                <TextField source="Requestor.Name.en" label="Name" />
                <PhoneNumberFiled source="Requestor.PhoneNumber" label="Phone No." />
                <EmailField source="Requestor.Email" label="Email Address" />
                <TextField source="Requestor.Detail" label="Request Details" />
              </Datagrid>
            </ListContextProvider>
          </Box>
        )}
        {filterValues.tableName === 'fieldevent' && (
          <Box className={classes.content}>
            <Box className={classes.head}>Event Registrants</Box>
            <ListContextProvider value={{ ...listContext }}>
              <Datagrid {...props} bulkActionButtons={false} optimized rowClick="edit">
                {/* <DateField source='SubmitDate' label='Date' /> */}
                <TextField source="Name.en" label="Name" />
                <BooleanField source="CheckedIn" label="Attended" />
                <PhoneNumberFiled source="PhoneNumber" label="Phone No." />
                <EmailField source="Email" />
              </Datagrid>
            </ListContextProvider>
          </Box>
        )}
        {filterValues.tableName === 'maintenance' && (
          <Box className={classes.content}>
            <Box className={classes.head}>Maintenance Report</Box>
            <ListContextProvider value={{ ...listContext }}>
              <Datagrid {...props} bulkActionButtons={false} optimized>
                <DateField source="Date" label="Request Date" />
                <TextField source="Name.en" label="Full Name" />
                <TextField source="Urgency" label="Priority" />
                <TextField source="EntityType" label="Location" />
                <TextField source="Unit" label="Unit" />
                <TextField source="Detail" label="Request Details" />
                <ImageField source="Image" label="Image URL" className={classes.image} />
                <TextField source="RequestTypeDisplayText.en" label="Request Type" />
                <TextField source="SubRequestTypeDisplayText.en" label="Sub-Request Type" />
              </Datagrid>
            </ListContextProvider>
          </Box>
        )}
        {filterValues.tableName === 'qrSupportReport' && (
          <Resource name="qrSupportReport" key="qrSupportReport" list={QRSupportReport} />
        )}
        {filterValues.tableName === 'roomRental' ? (
          !getSubList ? (
            <Box className={classes.content}>
              <Box className={classes.head}>Room Rental</Box>
              <ListContextProvider value={{ ...listContext }}>
                {/* @ts-ignore */}
                <Datagrid
                  {...props}
                  bulkActionButtons={false}
                  optimized
                  //@ts-ignore
                  rowClick={fieldmarketingClick}
                  currentSort={{ field: 'CreatedDate', order: 'DESC' }}
                >
                  <TextField source="EventName" label="Room Name" />
                  <TextField source="Unit" label="Unit" />
                  <TextField source="ParticipantNumber" label="No. of Requests" />
                </Datagrid>
              </ListContextProvider>
            </Box>
          ) : (
            <Box className={classes.content}>
              <Box sx={{ padding: '12px' }}>
                <IconButton edge="start" color="inherit" className={classes.backIcon} onClick={onBack}>
                  <ArrowBackIcon style={{ marginRight: 8 }} /> BACK
                </IconButton>
              </Box>
              <Box className={classes.head}>
                {/* @ts-ignore */}
                {`Room Rental - ${selectItem?.EventName || ''}, Unit ${selectItem?.Unit || ''}`}
              </Box>
              <ListContextProvider value={{ ...listContext }}>
                <Datagrid {...props} bulkActionButtons={false} optimized>
                  <TextField source="Name" label="Name" />
                  <EmailField source="Email" label="Email Address" />
                  <PhoneNumberFiled source="PhoneNumber" label="Mobile No." />
                  <TextField source="Unit" label="Unit" />
                  {/* @ts-ignore */}
                  <DateField source="RentalDate" label="Rental Date" {...dateFormat} />
                  <TextField source="StartTime" label="Start Time" />
                  <TextField source="EndTime" label="End Time" />
                  <TextField source="NumberOfGuests" label="No. of People " />
                </Datagrid>
              </ListContextProvider>
            </Box>
          )
        ) : null}
      </div>
    </Fragment>
  )
}

const MarketingList = (props: ListProps) => {
  const classes = useDatagridStyles()
  const { selectedGroupId, user, selectedPropertyId } = useSelector((state) => state.profile)
  const { pathname } = window.location
  const bId = useMemo(() => (pathname?.indexOf('report') > 0 ? selectedPropertyId : ''), [selectedPropertyId, pathname])
  const [type, setType] = useState<string>()
  const navigate = useNavigate()
  const currentGroupId = selectedGroupId || user?.UserContext?.CurrentGroupId
  const onTabChange = useCallback((tab: string) => {
    const item = tabs.find((item) => item.id === tab)
    setType(item?.type)
  }, [])
  const onClick = useCallback(() => {
    navigate(bId ? '/subscription' : '/subscriptions', {
      state: { type, bId },
    })
  }, [type, bId])
  return (
    <List
      actions={
        <Box className={classes.action}>
          {!!type && (
            <IconButton onClick={onClick}>
              <GroupOutlined htmlColor="#466CB5" />
            </IconButton>
          )}
          <ExportButton />
        </Box>
      }
      {...props}
      filterDefaultValues={{ tableName: 'vacancy', gId: currentGroupId, bId }}
      sort={{ field: 'CreatedDate', order: 'DESC' }}
      perPage={25}
    >
      <TabbedDatagrid onTabChange={onTabChange} bId={bId} />
    </List>
  )
}

export default MarketingList
