import React, { useCallback, useEffect, useState } from 'react'
import { isCanSavaQr } from '@/utils'
import { QRCodeFunctionType } from '@/types'
import { makeStyles } from '@mui/styles'
import { DateValidationError, MobileDatePicker, PickerChangeHandlerContext } from '@mui/x-date-pickers'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import { Event, ExpandMore } from '@mui/icons-material'
import WithDefaultData from '@/hoc/WithDefaultData'

interface Props {
  onChange?: (staticForm: any) => void
  value?: any
  disabledEdit?: boolean
  isEdit?: boolean
}

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 16px',
    },
    title: {
      color: '#0D1F2C',
      fontSize: 12,
    },
    date: {
      margin: '20px 0 0',
    },
  }
})

export const INSPECTION_REQUIRED = {
  LastInspectionDate: '',
  Frequency: '',
}

const InspectionPanel: React.FC<Props> = ({ onChange, value, disabledEdit = false, isEdit }) => {
  const [state, setState] = useState(value || {})
  const classes = useStyles()
  const handle = useCallback(
    (name, value) => {
      setState((prevState: any) => ({
        ...prevState,
        [name]: value,
      }))
      const newData = Object.assign({}, state, { [name]: value, isChange: true })
      Object.assign(newData, { CanSave: isCanSavaQr(QRCodeFunctionType.Inspection, newData) })
      setState(newData)
      onChange?.(newData)
    },
    [state]
  )

  const handleDateChange = (date: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => {
    let dateNum: number | undefined = undefined
    // @ts-ignore
    if (!date || date === 'Invalid Date') {
      dateNum = undefined
    } else {
      dateNum = new Date(date as unknown as string).getTime()
    }
    handle('LastInspectionDate', dateNum)
  }

  useEffect(() => {
    handle('LastInspectionDate', value?.LastInspectionDate || null)
    handle('Frequency', value?.Frequency || '')
  }, [value?.Frequency, value?.LastInspectionDate])

  return (
    <div className={classes.container}>
      <MobileDatePicker
        className={classes.date}
        label="Last Inspection Date*"
        format="MM/dd/yyyy"
        value={state?.LastInspectionDate || null}
        onChange={handleDateChange}
        disabled={disabledEdit || isEdit}
        disableFuture
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <Event />
                </InputAdornment>
              ),
            },
          },
        }}
      />

      <Autocomplete
        value={state?.Frequency || ''}
        onChange={(e, value) => handle('Frequency', value)}
        options={['Week', 'Month', 'Quarter', 'Annual']}
        style={{ width: '100%', marginBottom: '10px', marginTop: '20px' }}
        renderInput={(params) => <TextField {...params} label="Frequency" variant="outlined" required />}
        popupIcon={<ExpandMore />}
      />
    </div>
  )
}

export default WithDefaultData(InspectionPanel)
