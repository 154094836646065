import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslate } from 'react-admin'
import BackButton from '@component/BackButton/BackButton'
import CommentReviews from './CommentReviews'
import Echarts, { BarProps, EchartsProps, SeriesData, WordCloudProps } from '@component/Chart/Echarts'
import OverAllCard from '@component/Card/OverAllCard'
import { getCommentWordClouds, getEntityComments, getTopicSummary, viewOverallEntityTypeAnalytics } from '@api/index'
import {
  IComment,
  IEntityTypeAnalytic,
  IStarBucket,
  ITopic,
  IWordCloud,
  ResponseComment,
  ResponseTopics,
} from '@/types'
import { STAR_OPTIONS, COLOR_MAP, DEFAULT_DATE_RANGE_TODAY } from '../../global'
import EmptyDisplay from '@component/Empty/EmptyDisplay'
import { useSelector } from 'react-redux'
import CustomDateRange from '@component/DateSelect/CustomDateRange'

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: 'Roboto',
    color: '#504c4c',
    fontSize: 24,
    marginLeft: 10,
  },
  container: {
    display: 'flex',
    padding: '16px 16px 21px',
  },
  title: {
    minHeight: 52,
    fontSize: 24,
    fontFamily: 'Roboto',
    color: '#5e5d5d',
  },
  divider: {
    marginTop: 10,
  },
  notchedOutline: {
    borderColor: '#A29D9D',
  },
}))

const colorLost = ['#7CBF85', '#E25858']

const inRange = (num: number, a: number, b: number, threshold = 0) =>
  Math.min(a, b) - threshold <= num && num <= Math.max(a, b) + threshold

const BASE_PIE_SERIES_OPTION = [
  {
    radius: ['40%', '45%'],
    shadow: '48%',
    center: ['75%', '25%'],
    fontSize: 21,
  },
  {
    radius: ['43%', '48%'],
    shadow: '51%',
    center: ['25%', '75%'],
    fontSize: 22,
  },
  {
    radius: ['30%', '34%'],
    shadow: '36%',
    center: ['75%', '75%'],
    fontSize: 16,
  },
  {
    radius: ['23%', '27%'],
    shadow: '29%',
    center: ['25%', '25%'],
    fontSize: 12,
  },
]

const PIE_POSTION_MAP = {}

const getPierOption = (topics: ITopic[]) => {
  const series: any[] = []
  if (topics.length <= 0) {
    return {}
  } else if (topics.length >= 4) {
    topics = topics.slice(0, 4)
  }
  const arr = topics.map((item) => item.TotalScoreCount)
  const maxData = Math.max(...arr)
  for (let i = 0; i < topics.length; i++) {
    const topic = topics[i]
    const seriesData = [
      {
        value: topic.PositiveScoreCount,
        name: topic.Topic,
      },
      {
        value: topic.NegativeScoreCount,
        name: ` ${topic.Topic} `, // 'NegativeScoreCount'
      },
    ]
    const maxRadius = Math.max((topic.TotalScoreCount / maxData) * 0.45, 0.2)
    const maxRadiusPercent = Math.floor(maxRadius * 100) + '%'
    const shadowPercent = Math.floor((maxRadius + 0.02) * 100) + '%'
    const minRadiusPercent = Math.floor((maxRadius - 0.05) * 100) + '%'
    const seriesOption = BASE_PIE_SERIES_OPTION[i]
    seriesOption &&
      series.push({
        type: 'pie',
        radius: ['0%', shadowPercent],
        center: seriesOption.center,
        silent: true,
        hoverAnimation: false,
        clockWise: true,
        tooltip: {
          show: false,
        },
        label: {
          show: false,
        },
        itemStyle: {
          normal: {
            color: '#ffffff',
            shadowBlur: 6,
            shadowColor: '#66666a',
          },
        },
        data: [100],
      })
    seriesOption &&
      series.push({
        type: 'pie',
        radius: [minRadiusPercent, maxRadiusPercent],
        center: seriesOption.center,
        label: {
          show: true,
          position: 'center',
          fontSize: seriesOption.fontSize,
          fontWeight: 'bold',
        },
        silent: true,
        data: seriesData,
      })
  }
  const option = {
    type: 'pie',
    id: 'piechart-1',
    width: '100%',
    height: '300px',
    options: {
      color: colorLost,
      series,
    },
  }
  return option
}

const getWordOption = (seriesData: IWordCloud[]): WordCloudProps & EchartsProps => {
  return {
    id: 'cloudchart',
    type: 'wordCloud',
    // shape: 'circle',
    width: '100%',
    height: '100%',
    seriesData: [...seriesData].slice(0, 100).map((item: IWordCloud) => {
      const colorArr = ['#E30613', '#F8C636', '#4BCF5D'] //红色-消极 橙色-中性 绿色-积极
      let index = 1
      if (item.SentimentScore > 0) {
        index = 2
      }
      if (item.SentimentScore < 0) {
        index = 0
      }
      return {
        name: item.Label,
        value: item.Value,
        textStyle: {
          color: colorArr[index] || 'red',
        },
      }
    }),
  }
}

const getBarOption = (seriesData: SeriesData): BarProps & EchartsProps => {
  return {
    id: 'bar',
    type: 'bar',
    width: '100%',
    height: '100%',
    xAxisData: ['5-Star', '4-Star', '3-Star', '2-Star', '1-Star'],
    seriesData,
  }
}

const WordPlaceHolder = () => (
  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
    <img
      src="https://static.vecteezy.com/system/resources/previews/005/006/031/large_2x/no-result-data-document-or-file-not-found-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-etc-vector.jpg"
      height={250}
    />
  </Box>
)
const CommentPlaceHolder = () => (
  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
    <img
      src="https://cdn.dribbble.com/users/2382015/screenshots/6065978/media/1273880a5b9cc8008301089835f37939.gif"
      height={300}
    />
  </Box>
)

const Overall = (props: any) => {
  const classes = useStyles()
  const translate = useTranslate()
  // const breakpoint = useBreakpoint()
  const [barDataOption, setBarDataOption] = useState<BarProps & EchartsProps>()
  const [loadingTopics, setLoadingTopics] = useState(true)
  const [loadingBarData, setLoadingBarData] = useState(true)
  const [loadingWordClouds, setLoadingWordClouds] = useState(true)
  const [loadingComments, setLoadingComments] = useState(true)
  const [topicsOption, setTopicsOption] = useState<BarProps & EchartsProps>()
  const [comments, setComments] = useState<IComment[] | null>(null)
  const [filterComments, setFilterComments] = useState<IComment[] | null>(null)
  const [wordClouds, setWordClouds] = useState<WordCloudProps & EchartsProps>()
  const { bId, et } = useParams<{ bId: string; et: string }>()
  const [averageScore, setAverageScore] = useState<number | null>(null)
  const { selectedGroupId } = useSelector((state) => state.profile)

  const [range, setRange] = useState<any>(DEFAULT_DATE_RANGE_TODAY)

  const onActionChange = (option: IOption) => {
    option.value
      ? setFilterComments(
          (comments || []).filter((item: IComment) => {
            return item.Score === option.value
          })
        )
      : setFilterComments(null)
  }

  const fetchData = useCallback(
    async (range) => {
      const params = {
        bId,
        et: et,
        gId: selectedGroupId,
      }
      if (range?.length === 2 && range[0]?.isValid() && range[1]?.isValid()) {
        Object.assign(params, { startDate: range[0].valueOf(), endDate: range[1].valueOf() })
      }
      const { AverageScore, BuildingId, StarBuckets } = await viewOverallEntityTypeAnalytics<IEntityTypeAnalytic>(
        params
      )
      //@ts-ignore
      const seriesData: SeriesData =
        Array.isArray(StarBuckets) &&
        StarBuckets.map((item: IStarBucket) => {
          return {
            value: item.Count,
            itemStyle: COLOR_MAP[`Score${item.Score}`],
          }
        })
      setLoadingBarData(false)
      setAverageScore(AverageScore)
      setBarDataOption(getBarOption(seriesData))
    },
    [bId, et, range]
  )
  const fetchWordClouds = useCallback(async (range) => {
    const params = { bId, et: et, gId: selectedGroupId }
    if (range?.length === 2 && range[0]?.isValid() && range[1]?.isValid()) {
      Object.assign(params, { startDate: range[0].valueOf(), endDate: range[1].valueOf() })
    }
    const result = await getCommentWordClouds<IWordCloud[]>(params)
    !!result.length && setWordClouds(getWordOption(result))
    setLoadingWordClouds(false)
  }, [])
  const fetchEntityComments = useCallback(async (range) => {
    const params = { bId, et: et, gId: selectedGroupId }
    if (range?.length === 2 && range[0]?.isValid() && range[1]?.isValid()) {
      Object.assign(params, { startDate: range[0].valueOf(), endDate: range[1].valueOf() })
    }
    const result = await getEntityComments<ResponseComment>(params)
    setComments(result?.Comments)
    setLoadingComments(false)
  }, [])

  const fetchTopicSummary = useCallback(async (range) => {
    const params = { bId, et: et, gId: selectedGroupId }
    if (range?.length === 2 && range[0]?.isValid() && range[1]?.isValid()) {
      Object.assign(params, { startDate: range[0].valueOf(), endDate: range[1].valueOf() })
    }
    const result = await getTopicSummary<ResponseTopics>(params)
    //@ts-ignore
    setTopicsOption(getPierOption(result.Topics))
    setLoadingTopics(false)
  }, [])

  useEffect(() => {
    fetchData(range)
    fetchWordClouds(range)
    fetchEntityComments(range)
    fetchTopicSummary(range)
  }, [fetchData, range])
  const reviews = (filterComments || comments) as IComment[]

  return (
    <Box sx={{ width: '100%', mt: 2, flexDirection: 'column', bgcolor: '#f7f7f7' }}>
      <BackButton />
      <Box
        sx={{
          display: 'flex',
          mt: 2,
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0 32px',
          justifyContent: 'space-between',
        }}
      >
        <Typography className={classes.header} variant="h3">
          {et || 'Overall Building'} Feedback
        </Typography>
        <CustomDateRange value={range} onChange={(value) => setRange(value)} label="Date Range" />
      </Box>
      <Grid container spacing={1} className={classes.container}>
        <OverAllCard
          title={`Average Rating ${averageScore || ''}`}
          showRating
          ratingValue={averageScore}
          loading={loadingBarData}
        >
          {barDataOption?.id && <Echarts {...barDataOption} id="barchart" />}
        </OverAllCard>
        <OverAllCard title="Top 4 Topics" loading={loadingTopics}>
          {topicsOption && topicsOption?.id ? <Echarts {...topicsOption} key="pieId" /> : <EmptyDisplay />}
        </OverAllCard>
        <OverAllCard
          title="Sentiment Analysis"
          showDivider
          loading={loadingWordClouds}
          extensible={true}
          dataSize={wordClouds?.seriesData?.length}
          key={`${(wordClouds?.seriesData?.length || 0) > 20 ? '740' : '311'}`}
        >
          {wordClouds?.id ? <Echarts {...wordClouds} /> : <WordPlaceHolder />}
        </OverAllCard>
        <OverAllCard
          title="Comments"
          loading={loadingComments}
          showDivider
          extensible={true}
          actions={STAR_OPTIONS}
          defaultAction={STAR_OPTIONS[0]}
          dataSize={wordClouds?.seriesData?.length}
          onActionChange={onActionChange}
        >
          {reviews?.length > 0 ? (
            <CommentReviews reviews={reviews} dataSize={wordClouds?.seriesData?.length} />
          ) : (
            <CommentPlaceHolder />
          )}
        </OverAllCard>
      </Grid>
    </Box>
  )
}

export default Overall
