import React, { useCallback, useEffect } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CommonCard from '@component/Card/CommonCard'
import Images from '@assets/images'
import useSetState from '@hooks/useSetState'
import { DEFAULT_DATE_RANGE_TODAY, DEFAULT_PERIOD_YEAR_TO_DATE } from '@/global'
import { getInspectionAggregate, getInspectionRoundReport } from '@api'
import { useSelector } from 'react-redux'
import { formatNumber } from '@utils/common'
import { Datagrid, DateField, List, Resource, TextField } from 'react-admin'
import { dateFormat } from '@constant/app'
import { Color } from '@analytics/feedback/InspectionReport'
import EmptyDisplay from '@component/Empty/EmptyDisplay'
import CustomDateRange from '@component/DateSelect/CustomDateRange'

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: '#F5F5F5',
    flex: 1,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    padding: '18px 0px 18px',
    marginTop: -104,
  },
  row: {
    display: 'flex',
    marginTop: 16,
  },
  card: {
    marginTop: 25,
    borderRadius: 8,
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
    padding: '10px 0 36px',
    backgroundColor: 'white',
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  report: {
    display: 'flex',
    borderRadius: 6,
    border: '1px solid #E8E8E8',
  },
  reportItem: {
    flex: 1,
    borderRight: '1px solid #E8E8E8',
    textAlign: 'center',
    color: '#211F1F',
    fontSize: '24px',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  reportText: {
    color: '#707070',
    fontSize: '16px',
    borderBottom: '1px solid #E8E8E8',
    whiteSpace: 'nowrap',
    padding: '12px 16px',
  },
  gridItemCantainer: {
    paddingRight: 24,
  },
  rowItem: {
    lineHeight: '58px',
    height: 58,
    boxSizing: 'border-box',
    alignItems: 'center',
    borderBottom: '1px solid #E8E8E8',
    fontSize: 18,
    color: '#222',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  gridChartContainer: {
    paddingTop: 0,
    paddingLeft: 0,
  },
  xAxis: {
    display: 'flex',
    height: 150,
    alignItems: 'flex-end',
  },
  label: {
    fontSize: 18,
    color: '#989090',
    transform: 'rotate(-30deg) translateY(12px)',
    transformOrigin: 'left bottom',
    width: 58,
    whiteSpace: 'nowrap',
    paddingLeft: 29,
  },
  rightRow: {
    display: 'flex',
    borderLeft: '1px solid #BCBCBC',
  },
  item: {
    width: 58,
    height: 58,
    boxSizing: 'border-box',
    borderRight: '1px solid #BCBCBC',
    borderBottom: '1px solid #BCBCBC',
    textAlign: 'center',
    lineHeight: '58px',
  },
  tip: {
    borderTop: '1px solid #BCBCBC',
    paddingLeft: '8px',
    color: '#989090',
  },
}))

const InspectionReportList: React.FC<{ bId?: string }> = ({ bId }) => {
  const classes = useStyles()
  const { selectedGroupId, user } = useSelector((state) => state.profile)
  const currentGroupId = selectedGroupId || user?.UserContext?.CurrentGroupId
  return (
    <List
      disableSyncWithLocation
      exporter={false}
      filterDefaultValues={{}}
      filter={{ gId: currentGroupId, bId, tu: DEFAULT_PERIOD_YEAR_TO_DATE.value }}
      perPage={25}
      sort={{ field: '', order: '' }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="InspectionType" label="Inspection Type" />
        <DateField source="DueDate" label="Date" {...dateFormat} />
        <TextField source="SubmittedStatus" label="Status" emptyText="" />
      </Datagrid>
    </List>
  )
}

const InspectionReport: React.FC = () => {
  const classes = useStyles()
  const {
    selectedGroupId,
    selectedPropertyId,
    propertyTag: { PropertyTagId: ptId = '' } = {},
  } = useSelector((state) => state.profile)
  const [data, setData] = useSetState<{ top: any; range: any; list: any[] }>({
    top: null as any,
    range: DEFAULT_DATE_RANGE_TODAY,
    list: [] as any[],
  })
  const { top, range, list } = data
  const fetchInspectionAggregate = useCallback(
    async (range) => {
      const params = { gId: selectedGroupId, ptId, bId: selectedPropertyId }
      if (range?.length === 2 && range[0]?.isValid() && range[1]?.isValid()) {
        Object.assign(params, { startDate: range[0].valueOf(), endDate: range[1].valueOf() })
      }
      const result = await getInspectionAggregate(params)
      setData({ top: result })
    },
    [selectedGroupId, setData, selectedPropertyId, ptId]
  )
  useEffect(() => {
    const params = { gId: selectedGroupId, ptId, bId: selectedPropertyId }
    if (range?.length === 2 && range[0]?.isValid() && range[1]?.isValid()) {
      Object.assign(params, { startDate: range[0].valueOf(), endDate: range[1].valueOf() })
    }
    getInspectionRoundReport(params).then((res: any) => {
      setData({ list: res?.Result })
    })
  }, [selectedGroupId, ptId, selectedPropertyId, range])
  useEffect(() => {
    fetchInspectionAggregate(range)
    localStorage.setItem(
      'DashboardDate',
      JSON.stringify({
        StartDate: range[0]?.valueOf(),
        EndDate: range[1]?.valueOf(),
      })
    )
  }, [fetchInspectionAggregate, range])
  return (
    <div className={classes.paper}>
      <div className={classes.headerRight}>
        <CustomDateRange value={range} onChange={(value) => setData({ range: value })} label="Date Range" />
      </div>
      <Box className={classes.card} style={{ padding: '24px' }}>
        <Grid container wrap="nowrap">
          <Grid item xs={3} className={classes.gridItemCantainer}>
            <Box style={{ padding: '150px 0 0' }}>
              <Grid item xs={12}>
                {list.map((item) => (
                  <Typography key={item.BuildingId} className={classes.rowItem}>
                    {item.BuildingName?.en}
                  </Typography>
                ))}
              </Grid>
            </Box>
          </Grid>

          {list.length === 0 && (
            <Grid item xs={12} sx={{ mt: '100px' }} alignSelf="center">
              <EmptyDisplay />
            </Grid>
          )}

          <Grid item className={classes.gridChartContainer}>
            <Box className={classes.xAxis}>
              {list[0]?.Inspections.map((item) => {
                return (
                  <Typography key={item.InspectionName} className={classes.label}>
                    {item.InspectionName}
                  </Typography>
                )
              })}
            </Box>

            {list.map(({ Inspections, BuildingId }, i) => {
              return (
                <Box className={classes.rightRow} key={BuildingId}>
                  {Inspections.map(({ InspectionName, SubmittedStatus = 'NA' }) => {
                    const [backgroundColor, text] = Color[SubmittedStatus]
                    return (
                      <Typography
                        key={InspectionName}
                        className={classes.item}
                        style={{ backgroundColor, borderTop: i === 0 ? '1px solid #BCBCBC' : '' }}
                      >
                        {text}
                      </Typography>
                    )
                  })}
                </Box>
              )
            })}
          </Grid>

          {list.length !== 0 && (
            <Grid item xs sx={{ ml: 10, maxWidth: '200px !important' }} alignSelf="flex-end">
              <Box sx={{ border: '1px solid #BCBCBC', borderRadius: '4px', minWidth: '140px' }}>
                <Typography style={{ textAlign: 'center', lineHeight: '48px' }}>Key</Typography>
                {['Completed', 'Overdue', 'Upcoming'].map((item) => {
                  const [backgroundColor, text] = Color[item]
                  return (
                    <Grid key={item} container className={classes.tip}>
                      <Grid item xs={8} sx={{ lineHeight: '36px' }}>
                        {item}
                      </Grid>
                      <Grid item xs={4} sx={{ backgroundColor, textAlign: 'center', lineHeight: '36px' }}>
                        {text}
                      </Grid>
                    </Grid>
                  )
                })}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <div className={classes.row}>
        <CommonCard
          isPercent
          value={(top?.Completed || 0) / 100}
          valueText={top?.Completed ? `${top?.Completed}%` : '-'}
          label="Completed"
          loading={!top}
        />
        <CommonCard label="Remaining" loading={!top} value={`${formatNumber(top?.Remaining)}`} icon={Images.event} />
        <CommonCard
          isPercent
          value={(top?.OnTimeInspection || 0) / 100}
          valueText={top?.OnTimeInspection ? `${top?.OnTimeInspection}%` : '-'}
          label="On-Time Inspections"
          loading={!top}
        />
      </div>
      <Box className={classes.card}>
        <Resource name="inspectionReport" list={<InspectionReportList bId={selectedPropertyId} />} />
      </Box>
    </div>
  )
}

export default InspectionReport
