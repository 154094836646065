import React, { useState, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { MenuItemLink, MenuProps, useSidebarState } from 'react-admin'
import SubMenu from './SubMenu'
import Images from '@assets/images'
import { getPermissions } from '@common/auth'

import { useLocation } from 'react-router-dom'

enum MenuNameEnum {
  Account = 'Account',
  Property = 'Property',
  Activate = 'Activate',
}

const Menu = ({ dense = false }: MenuProps) => {
  const permissions = getPermissions()
  const classes = useStyles()
  const location = useLocation()
  const [open] = useSidebarState()
  const { selectedGroupId, selectedGroupName, selectedPropertyId, selectedPropertyName, user } = useSelector(
    (state) => state.profile
  )

  const [[firstOpenMenu, openMenu], setOpenMenu] = useState<MenuNameEnum[]>([])
  const handleToggle = (menu: MenuNameEnum, idx: number = 1) => {
    setOpenMenu((prevState) => {
      const preMenu = prevState[idx]
      //@ts-ignore
      prevState[idx] = preMenu === menu ? '' : menu
      return [...prevState]
    })
  }
  const menu = useMemo(() => {
    const {
      ViewAnalytics = false,
      ActivateCustomerFeedback = false,
      ManageGroups = false,
      ManageEntities = false,
      ManagePropertyInfo = false,
      ManageContacts = false,
      ManageMarketing = false,
      ManageReports = false,
      ManageLibraries = false,
    } = permissions || {}
    const selectedEntity = !!selectedPropertyName
    const routerName = location.pathname.split('/')[1]

    return [
      {
        menuKey: MenuNameEnum.Account,
        primaryText: selectedGroupName,
        show: ManageGroups,
        leftIcon: <img src={Images.Menu.accountName} alt="logo" />,
        child: [
          {
            pathname: `/accountCreate/${selectedGroupId}`,
            primaryText: 'Account Info',
            leftIcon: <img src={Images.Menu.accountInfo} alt="logo" />,
            show: ManageGroups,
          },
          {
            pathname: '/properties',
            primaryText: 'Buildings',
            leftIcon: <img src={Images.Menu.properties} alt="logo" />,
            show: ManageEntities,
          },
          {
            pathname: '/portfolioTeam',
            primaryText: 'Portfolio Team',
            leftIcon: <img src={Images.Menu.contacts} alt="logo" />,
            show: ManageGroups,
          },
          {
            pathname: '/portfolioAnalytics',
            primaryText: 'Portfolio Analytics',
            leftIcon: <img src={Images.Menu.portfolioAnalytics} alt="logo" />,
            show: ViewAnalytics,
          },
        ],
      },
      {
        menuKey: MenuNameEnum.Property,
        primaryText: selectedPropertyName,
        show: selectedEntity,
        leftIcon: <img src={Images.Menu.propertyName} alt="logo" />,
        child: [
          {
            pathname: `/propertyAdd/${selectedPropertyId}`,
            primaryText: 'Building Info',
            leftIcon: <img src={Images.Menu.propertyInfo} alt="logo" />,
            show: ManagePropertyInfo,
          },
          {
            pathname: '/marketing/customerPortal',
            primaryText: 'Marketing',
            leftIcon: <img src={Images.Menu.marketing} alt="logo" />,
            show: ManageMarketing,
          },
          {
            pathname: '/contacts/contactList',
            primaryText: 'Contacts',
            leftIcon: <img src={Images.Menu.contacts} alt="logo" />,
            show: ManageContacts,
          },
          {
            pathname: '/library/qrcodelist',
            primaryText: 'Libraries',
            leftIcon: <img src={Images.Menu.library} alt="logo" />,
            selected: [
              'library',
              'twoQREdit',
              'qrcode',
              'roundsEngineBuilder',
              'propertyQrGenerator',
              'customerPortal',
            ].includes(routerName),
            show: ManageLibraries,
          },
          {
            pathname: '/analytics/dashboard',
            primaryText: 'Analytics',
            leftIcon: <img src={Images.Menu.analytics} alt="logo" />,
            show: ManageReports, //ViewReports,
          },
        ],
      },
      {
        menuKey: MenuNameEnum.Activate,
        primaryText: 'Activate',
        show: ActivateCustomerFeedback,
        leftIcon: <img src={Images.Menu.activate} alt="logo" />,
        child: [
          {
            pathname: '/customerSurvey',
            primaryText: 'Customer Survey',
            leftIcon: <img src={Images.Menu.customerSurvey} alt="logo" />,
          },
          {
            pathname: '/surveyLibrary',
            primaryText: 'Activate Survey',
            leftIcon: <img src={Images.Menu.surveyLibrary} alt="logo" />,
          },
          {
            pathname: '/reportBuilder',
            primaryText: 'Report Builder',
            leftIcon: <img src={Images.Menu.reportBuilder} alt="logo" />,
          },
          {
            pathname: '/digitalProofReport',
            primaryText: 'Digital Proof',
            leftIcon: <img src={Images.Menu.digitalProof} alt="logo" />,
          },
          {
            pathname: '/installationRound',
            primaryText: 'Installation',
            leftIcon: <img src={Images.Menu.installation} alt="logo" />,
          },
          {
            pathname: '/activateReport',
            primaryText: 'Customer Feedback',
            leftIcon: <img src={Images.Menu.customerFeedback} alt="logo" />,
          },
          {
            pathname: '/scans/qrLocation',
            primaryText: 'Scans',
            leftIcon: <img src={Images.Menu.scans} alt="logo" />,
          },
          {
            pathname: '/masterLibrary/printLibrary',
            primaryText: 'Master Library',
            leftIcon: <img src={Images.Menu.masterLibrary} alt="logo" />,
          },
          {
            pathname: '/googleRatingReport',
            primaryText: 'Rating Report',
            leftIcon: <img src={Images.Menu.analytics} alt="logo" />,
          },
        ],
      },
    ]
  }, [selectedGroupId, selectedGroupName, selectedPropertyId, selectedPropertyName, permissions, location.pathname])

  useEffect(() => {
    const { pathname } = location
    let routerName = pathname.split('/')[1]
    if (routerName?.includes('?')) {
      routerName = routerName.split('?')[0]
    }
    console.log('routerName', routerName)

    if (['accountCreate', 'properties', 'portfolioAnalytics'].includes(routerName)) {
      handleToggle(MenuNameEnum.Account, 0)
    } else if (['propertyAdd', 'marketing', 'contacts', 'library', 'twoQREdit', 'analytics'].includes(routerName)) {
      handleToggle(MenuNameEnum.Property, 0)
    } else if (
      [
        'activateReport',
        'customerSurvey',
        'installationRound',
        'digitalProofReport',
        'masterLibrary',
        'scans',
      ].includes(routerName)
    ) {
      handleToggle(MenuNameEnum.Activate, 0)
    }
  }, [])

  const loop = (arr: any[], level: number) => {
    return arr.map((item, idx) => {
      const { pathname, primaryText, leftIcon, show = true, child, ...other } = item
      if (!show) return null
      if (child) {
        const { menuKey } = item
        return (
          <div key={menuKey}>
            <SubMenu
              handleToggle={() => handleToggle(menuKey, level > 1 ? 1 : 0)}
              isOpen={menuKey === (level > 1 ? openMenu : firstOpenMenu)}
              name={primaryText}
              icon={leftIcon}
              dense={dense}
              rootStyle={{ paddingLeft: level > 1 ? 24 : 16 }}
            >
              {loop(child as typeof menu, level + 1)}
            </SubMenu>
            {idx !== arr.length - 1 && <div style={{ borderBottom: '1px solid #BCBCBC', marginTop: 16 }} />}
          </div>
        )
      }
      return (
        <MenuItemLink
          key={`${level}-${idx}-${pathname}`}
          to={{ pathname }}
          state={{ _scrollToTop: true }}
          primaryText={primaryText}
          leftIcon={leftIcon}
          className={classes.menulink}
          dense={dense}
          {...other}
        />
      )
    })
  }

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
      })}
    >
      {loop(menu, 1)}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 225,
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '& .RaMenuItemLink-active': {
      backgroundColor: '#F5F5F5',
      borderRadius: 6,
      height: 33,
    },
  },
  open: {
    // width: 349,
  },
  closed: {
    width: 100,
  },
  menuIcon: {
    color: '#211F1F',
    width: 12,
    height: 12,
  },
  menulink: {
    color: '#211F1F',
    fontSize: 14,
    height: 33,
    borderRadius: 6,
    fontWeight: 600,
    marginTop: 12,
    '& .RaMenuItemLink-icon': {
      minWidth: 24,
      marginRight: 12,
    },
  },
}))

export default Menu
